import { PublicClientApplication, Configuration } from "@azure/msal-browser";
import { Client } from "@microsoft/microsoft-graph-client";

const msalConfig: Configuration = {
  auth: {
    clientId: "60fdd6aa-3954-49ba-9b3f-6d03b26bf479",
    authority: "https://login.microsoftonline.com/418e2a4a-28fc-41c3-bdec-56078f098d6f",
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};

let msalInstance: PublicClientApplication | null = null;

export async function initializeMsal() {
  if (!msalInstance) {
    msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
  }
  return msalInstance;
}

export async function getMicrosoftAuthToken() {
  try {
    const instance = await initializeMsal();
    if (!instance) throw new Error('MSAL niet geïnitialiseerd');

    const accounts = instance.getAllAccounts();
    if (accounts.length === 0) {
      const response = await instance.loginPopup({
        scopes: ['Calendars.ReadWrite', 'User.Read']
      });
      if (response) {
        instance.setActiveAccount(response.account);
      }
    }

    const account = instance.getActiveAccount();
    if (!account) throw new Error('Geen actief account');

    const response = await instance.acquireTokenSilent({
      scopes: ['Calendars.ReadWrite', 'User.Read'],
      account: account
    });

    return response.accessToken;
  } catch (error) {
    console.error('Microsoft auth error:', error);
    throw error;
  }
}

export async function getMicrosoftCalendarEvents(startDate: Date, endDate: Date) {
  const token = await getMicrosoftAuthToken();
  
  const client = Client.init({
    authProvider: (done) => {
      done(null, token);
    }
  });

  const events = await client
    .api('/me/calendar/events')
    .filter(`start/dateTime ge '${startDate.toISOString()}' and end/dateTime le '${endDate.toISOString()}'`)
    .get();

  return events.value;
}

export async function handleMicrosoftRedirect() {
  try {
    const instance = await initializeMsal();
    if (!instance) return false;

    const response = await instance.handleRedirectPromise();
    if (response) {
      instance.setActiveAccount(response.account);
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error handling redirect:', error);
    return false;
  }
}