import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/layout/Layout';
import { AuthProvider } from './contexts/AuthContext';
import { LanguageProvider } from './contexts/LanguageContext';
import LoginComponent from './components/auth/LoginComponent';
import RegisterComponent from './components/auth/RegisterComponent';
import PasswordResetComponent from './components/auth/PasswordResetComponent';
import UserProfile from './components/profile/UserProfile';
import AdminDashboard from './components/admin/AdminDashboard';
import UserManagement from './components/admin/UserManagement';
import Dashboard from './components/dashboard/Dashboard';
import MobileDashboard from './components/mobile/MobileDashboard';
import ErrorBoundary from './components/error/ErrorBoundary';
import NotFound from './components/error/NotFound';
import BadRequest from './components/error/BadRequest';
import SMSDashboard from './modules/sms/components/SMSDashboard';
import VoIPDashboard from './modules/voip/components/VoIPDashboard';
import CalendarDashboard from './modules/calendar/components/CalendarDashboard';
import PrivateRoute from './components/auth/PrivateRoute';
import { initializeFirebase } from './services/firebase';
import AdminRoutes from './modules/admin/routes';

export function App() {
  useEffect(() => {
    initializeFirebase().catch(console.error);
  }, []);

  return (
    <ErrorBoundary>
      <AuthProvider>
        <LanguageProvider>
          <Layout>
            <Routes>
              <Route path="/login" element={<LoginComponent />} />
              <Route path="/registreren" element={<RegisterComponent />} />
              <Route path="/wachtwoord-vergeten" element={<PasswordResetComponent />} />
              
              <Route
                path="/profiel"
                element={
                  <PrivateRoute>
                    <UserProfile />
                  </PrivateRoute>
                }
              />

              {/* Admin routes */}
              <Route
                path="/admin/*"
                element={
                  <PrivateRoute>
                    <AdminRoutes />
                  </PrivateRoute>
                }
              />

              <Route
                path="/sms"
                element={
                  <PrivateRoute>
                    <SMSDashboard />
                  </PrivateRoute>
                }
              />

              <Route
                path="/voip"
                element={
                  <PrivateRoute>
                    <VoIPDashboard />
                  </PrivateRoute>
                }
              />

              <Route
                path="/calendar"
                element={
                  <PrivateRoute>
                    <CalendarDashboard />
                  </PrivateRoute>
                }
              />

              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    {window.innerWidth <= 768 ? <MobileDashboard /> : <Dashboard />}
                  </PrivateRoute>
                }
              />

              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/400" element={<BadRequest />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Layout>
        </LanguageProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;