import React from 'react';

function LoadingState() {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="text-gray-600">Gebruikers laden...</div>
    </div>
  );
}

export default LoadingState;