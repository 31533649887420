import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { Settings } from 'lucide-react';
import { useRoleAccess, ROLES } from '../../../hooks/useRoleAccess';
import { SMSConfig } from '../types/sms';
import { DEFAULT_SMS_CONFIG } from '../config/defaultConfig';
import SMSProviderConfig from './SMSProviderConfig';

function SMSSettings() {
  const { hasAccess: isSuperAdmin } = useRoleAccess([ROLES.SUPERADMIN]);
  const [config, setConfig] = useState<SMSConfig>(DEFAULT_SMS_CONFIG);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    loadConfig();
  }, []);

  async function loadConfig() {
    try {
      const configDoc = await getDoc(doc(db, 'sms_config', 'settings'));
      if (configDoc.exists()) {
        setConfig(configDoc.data() as SMSConfig);
      } else {
        // Create default config if it doesn't exist
        await updateDoc(doc(db, 'sms_config', 'settings'), DEFAULT_SMS_CONFIG);
      }
    } catch (err) {
      setError('Fout bij het laden van SMS configuratie');
    } finally {
      setLoading(false);
    }
  }

  async function handleConfigUpdate(newConfig: Partial<SMSConfig>) {
    try {
      setSaving(true);
      setError('');
      setSuccess('');

      const updatedConfig = { ...config, ...newConfig };
      await updateDoc(doc(db, 'sms_config', 'settings'), updatedConfig);
      
      setConfig(updatedConfig);
      setSuccess('SMS configuratie succesvol opgeslagen');
    } catch (err) {
      setError('Fout bij het opslaan van SMS configuratie');
    } finally {
      setSaving(false);
    }
  }

  if (loading) {
    return <div>Laden...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold flex items-center">
          <Settings className="h-6 w-6 mr-2" />
          SMS Instellingen
        </h2>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          {error}
        </div>
      )}

      {success && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded">
          {success}
        </div>
      )}

      <div className="bg-white rounded-lg shadow-md p-6">
        <SMSProviderConfig
          config={config}
          onUpdate={handleConfigUpdate}
          disabled={saving || !isSuperAdmin}
        />
      </div>
    </div>
  );
}

export default SMSSettings;