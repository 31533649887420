import React, { useState } from 'react';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import { X, MapPin, Phone, Clock, ExternalLink, FileText, User, Check, MessageSquare, Copy } from 'lucide-react';
import type { CalendarEvent, Calendar } from '../types';
import { formatPhoneNumber, extractPhoneNumber } from '../utils/phoneUtils';
import { updateEventStatus } from '../services/calendarService';
import { useAuth } from '../../../contexts/AuthContext';
import SMSComposer from '../../sms/components/SMSComposer';

interface Props {
  event: CalendarEvent;
  calendar?: Calendar;
  onClose: () => void;
  onStatusChange: (event: CalendarEvent) => void;
  onPhoneClick?: (phoneNumber: string) => void;
}

function EventDetailsModal({ event, calendar, onClose, onStatusChange, onPhoneClick }: Props) {
  const { currentUser } = useAuth();
  const [updating, setUpdating] = useState(false);
  const [showSMSComposer, setShowSMSComposer] = useState(false);
  const [copiedNumber, setCopiedNumber] = useState<string | null>(null);

  // Haal adres uit beschrijving of gebruik locatie veld
  const address = event.location || event.description?.split('\n').join(', ');
  const isInsezoCalendar = calendar?.provider === 'insezo';

  // Extract phone number from description or metadata
  const phoneNumber = event.metadata?.phoneNumber || extractPhoneNumber(event.description || '');

  const openInGoogleMaps = () => {
    if (address) {
      const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
      window.open(mapsUrl, '_blank');
    }
  };

  const handleComplete = async () => {
    if (!currentUser) return;
    
    try {
      setUpdating(true);
      const updatedEvent = await updateEventStatus(event.id, 'completed', currentUser.uid);
      onStatusChange(updatedEvent);
    } catch (err) {
      console.error('Error updating event status:', err);
    } finally {
      setUpdating(false);
    }
  };

  const handleCopyNumber = async () => {
    if (phoneNumber) {
      try {
        await navigator.clipboard.writeText(phoneNumber);
        setCopiedNumber(phoneNumber);
        setTimeout(() => setCopiedNumber(null), 2000);
      } catch (err) {
        console.error('Failed to copy number:', err);
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-lg max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-start p-6 border-b">
          <h2 className="text-xl font-semibold">{event.title}</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-6 space-y-4">
          <div className="flex items-start space-x-2 text-gray-600">
            <Clock className="h-5 w-5 mt-0.5" />
            <div>
              <p className="font-medium">
                {format(new Date(event.startTime), 'EEEE d MMMM yyyy', { locale: nl })}
              </p>
              <p>
                {format(new Date(event.startTime), 'HH:mm')} - 
                {format(new Date(event.endTime), 'HH:mm')}
              </p>
            </div>
          </div>

          {address && (
            <div className="flex items-start space-x-2">
              <MapPin className="h-5 w-5 mt-0.5 text-gray-600" />
              <div className="flex-1">
                <p className="text-gray-600">{address}</p>
                <button
                  onClick={openInGoogleMaps}
                  className="mt-2 inline-flex items-center text-primary hover:text-primary-dark text-sm"
                >
                  <ExternalLink className="h-4 w-4 mr-1" />
                  Open in Google Maps
                </button>
              </div>
            </div>
          )}

          {phoneNumber && (
            <div className="flex items-center space-x-4">
              <button
                onClick={() => onPhoneClick?.(phoneNumber)}
                className="flex items-center space-x-2 text-primary hover:text-primary-dark"
              >
                <Phone className="h-5 w-5" />
                <span>{formatPhoneNumber(phoneNumber)}</span>
              </button>

              <div className="flex space-x-2">
                <button
                  onClick={handleCopyNumber}
                  className="inline-flex items-center px-2 py-1 text-sm text-gray-600 hover:text-gray-800"
                >
                  <Copy className="h-4 w-4 mr-1" />
                  {copiedNumber === phoneNumber ? 'Gekopieerd!' : 'Kopieer'}
                </button>

                <button
                  onClick={() => setShowSMSComposer(true)}
                  className="inline-flex items-center px-2 py-1 text-sm text-primary hover:text-primary-dark"
                >
                  <MessageSquare className="h-4 w-4 mr-1" />
                  SMS versturen
                </button>
              </div>
            </div>
          )}

          <div className="pt-4 border-t">
            {event.status === 'completed' ? (
              <div className="flex items-center text-green-600">
                <Check className="h-5 w-5 mr-2" />
                <span>Afgerond</span>
                {event.completedBy && (
                  <span className="ml-2 text-sm text-gray-500">
                    door {event.completedBy}
                  </span>
                )}
              </div>
            ) : (
              <button
                onClick={handleComplete}
                disabled={updating}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                <Check className="h-4 w-4 mr-2" />
                Markeer als afgerond
              </button>
            )}
          </div>

          {calendar && (
            <div className="flex items-center mt-6 pt-4 border-t">
              <div
                className="w-3 h-3 rounded-full mr-2"
                style={{ backgroundColor: calendar.color }}
              />
              <span className="text-sm text-gray-600">{calendar.name}</span>
            </div>
          )}
        </div>
      </div>

      {showSMSComposer && phoneNumber && (
        <SMSComposer
          initialRecipient={phoneNumber}
          onClose={() => setShowSMSComposer(false)}
        />
      )}
    </div>
  );
}

export default EventDetailsModal;