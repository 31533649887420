import React, { useState, useEffect } from 'react';
import { Calendar as CalendarIcon, Plus, Settings } from 'lucide-react';
import { useAuth } from '../../../contexts/AuthContext';
import { useRoleAccess, ROLES } from '../../../hooks/useRoleAccess';
import CalendarList from './CalendarList';
import CalendarView from './CalendarView';
import AddCalendarModal from './AddCalendarModal';
import CalendarSettings from './CalendarSettings';
import MicrosoftCalendarConnect from './MicrosoftCalendarConnect';
import { getCalendars } from '../services/calendarService';
import type { Calendar } from '../types';

function CalendarDashboard() {
  const { currentUser } = useAuth();
  const { hasAccess: isAdmin } = useRoleAccess([ROLES.ADMIN, ROLES.SUPERADMIN]);
  const [calendars, setCalendars] = useState<Calendar[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    if (currentUser) {
      loadCalendars();
    }
  }, [currentUser]);

  async function loadCalendars() {
    try {
      setLoading(true);
      setError('');
      const loadedCalendars = await getCalendars(currentUser!.uid);
      setCalendars(loadedCalendars);
    } catch (err) {
      console.error('Error loading calendars:', err);
      setError('Fout bij het laden van agenda\'s');
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center">
          <CalendarIcon className="h-6 w-6 text-primary mr-2" />
          <h1 className="text-2xl font-bold">Agenda</h1>
        </div>
        
        <div className="flex space-x-4">
          <button
            onClick={() => setShowAddModal(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark"
          >
            <Plus className="h-5 w-5 mr-2" />
            Agenda Toevoegen
          </button>

          {isAdmin && (
            <button
              onClick={() => setShowSettings(true)}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              <Settings className="h-5 w-5 mr-2" />
              Instellingen
            </button>
          )}
        </div>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      {/* Microsoft 365 Integratie */}
      <div className="mb-6">
        <MicrosoftCalendarConnect />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        <div className="lg:col-span-1">
          <CalendarList 
            calendars={calendars} 
            onRefresh={loadCalendars}
          />
        </div>

        <div className="lg:col-span-3">
          <CalendarView calendars={calendars} />
        </div>
      </div>

      {showAddModal && (
        <AddCalendarModal
          onClose={() => setShowAddModal(false)}
          onSuccess={() => {
            setShowAddModal(false);
            loadCalendars();
          }}
        />
      )}

      {showSettings && (
        <CalendarSettings
          onClose={() => setShowSettings(false)}
          onSuccess={() => {
            setShowSettings(false);
            loadCalendars();
          }}
        />
      )}
    </div>
  );
}

export default CalendarDashboard;