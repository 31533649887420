import React from 'react';
import { Shield } from 'lucide-react';
import { useRoleAccess, ROLES } from '../../../hooks/useRoleAccess';
import { Navigate } from 'react-router-dom';

function SecuritySettings() {
  const { hasAccess } = useRoleAccess([ROLES.SUPERADMIN]);

  if (!hasAccess) {
    return <Navigate to="/admin" replace />;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center mb-6">
        <Shield className="h-6 w-6 text-primary mr-2" />
        <h1 className="text-2xl font-bold">Beveiligingsinstellingen</h1>
      </div>

      <div className="grid gap-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-lg font-semibold mb-4">Wachtwoordbeleid</h2>
          {/* Wachtwoordbeleid instellingen hier */}
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-lg font-semibold mb-4">Toegangsbeveiliging</h2>
          {/* Toegangsbeveiliging instellingen hier */}
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-lg font-semibold mb-4">Audit Log</h2>
          {/* Audit log hier */}
        </div>
      </div>
    </div>
  );
}

export default SecuritySettings;