import React, { useState } from 'react';
import { X } from 'lucide-react';
import { collection, getDocs, addDoc, deleteDoc, query, where } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { User } from './types';

interface Props {
  user: User;
  groups: any[];
  currentGroups: string[];
  onClose: () => void;
  onSave: () => void;
}

function ManageGroupsModal({ user, groups, currentGroups, onClose, onSave }: Props) {
  const [selectedGroups, setSelectedGroups] = useState<string[]>(currentGroups);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      // Get current memberships
      const membershipsSnapshot = await getDocs(
        query(
          collection(db, 'group_members'),
          where('userId', '==', user.id)
        )
      );

      // Delete removed memberships
      const deletePromises = membershipsSnapshot.docs
        .filter(doc => !selectedGroups.includes(doc.data().groupId))
        .map(doc => deleteDoc(doc.ref));

      await Promise.all(deletePromises);

      // Add new memberships
      const existingGroupIds = membershipsSnapshot.docs.map(doc => doc.data().groupId);
      const newGroupIds = selectedGroups.filter(groupId => !existingGroupIds.includes(groupId));

      const addPromises = newGroupIds.map(groupId =>
        addDoc(collection(db, 'group_members'), {
          groupId,
          userId: user.id,
          role: 'member',
          joinedAt: new Date().toISOString()
        })
      );

      await Promise.all(addPromises);
      onSave();
    } catch (err) {
      setError('Er is een fout opgetreden bij het bijwerken van de groepen.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Groepen Beheren</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="h-5 w-5" />
          </button>
        </div>

        <p className="text-gray-600 mb-4">
          Beheer groepen voor gebruiker: <strong>{user.email}</strong>
        </p>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            {groups.map(group => (
              <label key={group.id} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={selectedGroups.includes(group.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedGroups([...selectedGroups, group.id]);
                    } else {
                      setSelectedGroups(selectedGroups.filter(id => id !== group.id));
                    }
                  }}
                  className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
                />
                <span className="text-sm text-gray-700">{group.name}</span>
              </label>
            ))}
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Annuleren
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:opacity-50"
            >
              {loading ? 'Bezig met opslaan...' : 'Opslaan'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ManageGroupsModal;