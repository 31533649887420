import { SMSConfig } from '../types/sms';

export const DEFAULT_SMS_CONFIG: SMSConfig = {
  activeProvider: 'smsgateway',
  providers: {
    smsgateway: {
      name: 'SMSTools.be',
      apiUrl: 'https://api.smsgatewayapi.com/v1/message/send',
      clientId: '736817346436349198312',
      clientSecret: 'QUVdvUTsvLqwrkOOhSbgR',
      enabled: true
    },
    cheapconnect: {
      name: 'CheapConnect',
      apiUrl: 'https://api.cheapconnect.net/v1/sms',
      apiKey: '63b057ab-5fd4-4428-9e76-d79e8da61c8d',
      enabled: false
    }
  },
  defaultSender: 'VoIPManager'
};