import React from 'react';
import { Wifi, WifiOff, AlertTriangle } from 'lucide-react';

interface Props {
  status: 'connected' | 'disconnected' | 'connecting' | 'error';
  serverType: 'primary' | 'alternative';
  error?: string;
}

function VoIPStatusMonitor({ status, serverType, error }: Props) {
  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          {status === 'connected' && (
            <>
              <Wifi className="h-5 w-5 text-green-500" />
              <span className="text-green-500 font-medium">Verbonden</span>
              <span className="text-sm text-gray-500">
                ({serverType === 'primary' ? 'Primaire' : 'Alternatieve'} server)
              </span>
            </>
          )}
          {status === 'connecting' && (
            <>
              <Wifi className="h-5 w-5 text-yellow-500 animate-pulse" />
              <span className="text-yellow-500 font-medium">Verbinden...</span>
            </>
          )}
          {status === 'disconnected' && (
            <>
              <WifiOff className="h-5 w-5 text-red-500" />
              <span className="text-red-500 font-medium">Niet verbonden</span>
            </>
          )}
          {status === 'error' && (
            <>
              <AlertTriangle className="h-5 w-5 text-red-500" />
              <span className="text-red-500 font-medium">Verbindingsfout</span>
            </>
          )}
        </div>

        {error && (
          <div className="text-sm text-red-500">
            {error}
          </div>
        )}
      </div>
    </div>
  );
}

export default VoIPStatusMonitor;