export async function testVoIPConnection(server: string, port: number, username: string, password: string) {
  try {
    const response = await fetch('/.netlify/functions/voip-test', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        server,
        port,
        username,
        password
      })
    });

    if (!response.ok) {
      throw new Error('Test request failed');
    }

    return await response.json();
  } catch (error) {
    console.error('VoIP test error:', error);
    return { error: 'CONNECTION_FAILED' };
  }
}