import React from 'react';
import { Users } from 'lucide-react';

interface Props {
  hasAccess: boolean;
}

function Header({ hasAccess }: Props) {
  return (
    <div className="flex justify-between items-center mb-6">
      <h1 className="text-2xl font-bold flex items-center">
        <Users className="h-6 w-6 mr-2" />
        Gebruikersbeheer
      </h1>
    </div>
  );
}

export default Header;