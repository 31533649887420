import React from 'react';
import { Key } from 'lucide-react';
import { useRoleAccess, ROLES } from '../../../hooks/useRoleAccess';
import { Navigate } from 'react-router-dom';

function APISettings() {
  const { hasAccess } = useRoleAccess([ROLES.SUPERADMIN]);

  if (!hasAccess) {
    return <Navigate to="/admin" replace />;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center mb-6">
        <Key className="h-6 w-6 text-primary mr-2" />
        <h1 className="text-2xl font-bold">API Instellingen</h1>
      </div>

      <div className="grid gap-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-lg font-semibold mb-4">API Sleutels</h2>
          {/* API sleutel beheer hier */}
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-lg font-semibold mb-4">Toegangsrechten</h2>
          {/* API toegangsrechten hier */}
        </div>
      </div>
    </div>
  );
}

export default APISettings;