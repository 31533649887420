import React from 'react';
import VoIPClient from './VoIPClient';
import { Phone } from 'lucide-react';

function VoIPDashboard() {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center mb-6">
        <Phone className="h-6 w-6 text-primary mr-2" />
        <h1 className="text-2xl font-bold">VoIP Dashboard</h1>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <VoIPClient />
      </div>
    </div>
  );
}

export default VoIPDashboard;