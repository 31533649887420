import { collection, query, getDocs, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../services/firebase';

export interface SMSTemplate {
  id: string;
  name: string;
  content: string;
  category: string;
  isDefault?: boolean;
  createdBy: string;
  createdAt: string;
}

export async function getTemplates() {
  try {
    const templatesQuery = query(collection(db, 'sms_templates'));
    const snapshot = await getDocs(templatesQuery);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as SMSTemplate[];
  } catch (error) {
    console.error('Error loading templates:', error);
    throw new Error('Fout bij het laden van templates');
  }
}

export async function createTemplate(template: Omit<SMSTemplate, 'id' | 'createdAt'>) {
  try {
    const docRef = await addDoc(collection(db, 'sms_templates'), {
      ...template,
      createdAt: new Date().toISOString()
    });
    return docRef.id;
  } catch (error) {
    console.error('Error creating template:', error);
    throw new Error('Fout bij het aanmaken van template');
  }
}

export async function updateTemplate(id: string, template: Partial<SMSTemplate>) {
  try {
    await updateDoc(doc(db, 'sms_templates', id), {
      ...template,
      updatedAt: new Date().toISOString()
    });
  } catch (error) {
    console.error('Error updating template:', error);
    throw new Error('Fout bij het bijwerken van template');
  }
}

export async function deleteTemplate(id: string) {
  try {
    await deleteDoc(doc(db, 'sms_templates', id));
  } catch (error) {
    console.error('Error deleting template:', error);
    throw new Error('Fout bij het verwijderen van template');
  }
}