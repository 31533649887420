import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertTriangle, AlertCircle, Ban, RefreshCw, Home, ArrowLeft } from 'lucide-react';

interface Props {
  code: number;
  error?: Error | null;
  errorInfo?: any;
}

const ERROR_TYPES = {
  400: {
    title: 'Ongeldige aanvraag',
    description: 'De server begrijpt de aanvraag niet vanwege ongeldige syntax.',
    icon: Ban,
    color: 'text-yellow-500'
  },
  404: {
    title: 'Pagina niet gevonden',
    description: 'De opgevraagde pagina bestaat niet of is verplaatst.',
    icon: AlertTriangle,
    color: 'text-blue-500'
  },
  500: {
    title: 'Server fout',
    description: 'Er is een onverwachte fout opgetreden op de server.',
    icon: AlertCircle,
    color: 'text-red-500'
  }
};

function ErrorPage({ code, error, errorInfo }: Props) {
  const navigate = useNavigate();
  const errorType = ERROR_TYPES[code as keyof typeof ERROR_TYPES] || ERROR_TYPES[500];
  const Icon = errorType.icon;

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="max-w-lg w-full bg-white rounded-lg shadow-lg p-8">
        <div className="flex flex-col items-center text-center">
          <div className={`p-4 rounded-full ${errorType.color} bg-opacity-10 mb-4`}>
            <Icon className={`h-12 w-12 ${errorType.color}`} />
          </div>

          <h1 className="text-4xl font-bold text-gray-900 mb-2">
            {code} - {errorType.title}
          </h1>
          
          <p className="text-gray-600 mb-8">
            {errorType.description}
          </p>

          {error && (
            <div className="w-full bg-gray-50 rounded-lg p-4 mb-6 text-left">
              <p className="text-sm font-medium text-gray-700 mb-2">
                Foutdetails:
              </p>
              <p className="text-sm text-gray-600 font-mono break-all">
                {error.message}
              </p>
              {errorInfo && (
                <details className="mt-2">
                  <summary className="text-sm text-gray-500 cursor-pointer hover:text-gray-700">
                    Stack trace
                  </summary>
                  <pre className="mt-2 text-xs text-gray-600 overflow-auto max-h-40">
                    {errorInfo.componentStack}
                  </pre>
                </details>
              )}
            </div>
          )}

          <div className="flex flex-wrap justify-center gap-4">
            <button
              onClick={handleGoBack}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              <ArrowLeft className="h-4 w-4 mr-2" />
              Ga terug
            </button>

            <button
              onClick={handleGoHome}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              <Home className="h-4 w-4 mr-2" />
              Naar home
            </button>

            <button
              onClick={handleRefresh}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              <RefreshCw className="h-4 w-4 mr-2" />
              Vernieuw pagina
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;