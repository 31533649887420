import React from 'react';
import { Bell } from 'lucide-react';

function NotificationSettings() {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center mb-6">
        <Bell className="h-6 w-6 text-primary mr-2" />
        <h1 className="text-2xl font-bold">Notificatie Instellingen</h1>
      </div>

      <div className="grid gap-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-lg font-semibold mb-4">Systeem Notificaties</h2>
          {/* Systeem notificatie instellingen hier */}
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-lg font-semibold mb-4">E-mail Notificaties</h2>
          {/* E-mail notificatie instellingen hier */}
        </div>
      </div>
    </div>
  );
}

export default NotificationSettings;