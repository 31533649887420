import React, { useState, useEffect } from 'react';
import { Phone, Save, PlayCircle } from 'lucide-react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { useRoleAccess, ROLES } from '../../../hooks/useRoleAccess';
import ServerSettings from '../../voip/components/ServerSettings';
import SIPCredentials from '../../voip/components/SIPCredentials';
import VoIPTestResult from '../../voip/components/VoIPTestResult';
import { testVoIPConnection } from '../../voip/services/testService';
import { ERROR_EXPLANATIONS } from '../../voip/constants/errorMessages';

function VoIPSettings() {
  const { hasAccess } = useRoleAccess([ROLES.ADMIN, ROLES.SUPERADMIN]);
  const [config, setConfig] = useState({
    provider: 'cheapconnect',
    server: {
      primary: 'voip.cheapconnect.net',
      alternative: 'sip.cheapconnect.net',
      port: 5080,
      useAlternative: false
    },
    sipCredentials: {
      username: '31453690196',
      password: 'aaexe4uMekyg8'
    }
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [testing, setTesting] = useState(false);
  const [error, setError] = useState('');
  const [testError, setTestError] = useState<keyof typeof ERROR_EXPLANATIONS | null>(null);
  const [success, setSuccess] = useState('');
  const [testSuccess, setTestSuccess] = useState(false);

  useEffect(() => {
    loadConfig();
  }, []);

  async function loadConfig() {
    try {
      const configDoc = await getDoc(doc(db, 'system_config', 'voip_settings'));
      if (configDoc.exists()) {
        setConfig(configDoc.data());
      }
    } catch (err) {
      setError('Fout bij het laden van VoIP instellingen');
    } finally {
      setLoading(false);
    }
  }

  async function handleSave() {
    try {
      setSaving(true);
      setError('');
      setSuccess('');

      await updateDoc(doc(db, 'system_config', 'voip_settings'), {
        ...config,
        updatedAt: new Date().toISOString()
      });

      setSuccess('VoIP instellingen succesvol opgeslagen');
    } catch (err) {
      setError('Fout bij het opslaan van VoIP instellingen');
    } finally {
      setSaving(false);
    }
  }

  async function handleTest() {
    try {
      setTesting(true);
      setTestError(null);
      setTestSuccess(false);

      const server = config.server.useAlternative ? config.server.alternative : config.server.primary;
      const result = await testVoIPConnection(
        server,
        config.server.port,
        config.sipCredentials.username,
        config.sipCredentials.password
      );

      if (result.error) {
        setTestError(result.error);
      } else {
        setTestSuccess(true);
      }
    } catch (err) {
      setTestError('CONNECTION_FAILED');
    } finally {
      setTesting(false);
    }
  }

  if (!hasAccess) {
    return (
      <div className="text-center py-8 text-red-600">
        U heeft geen toegang tot deze pagina.
      </div>
    );
  }

  if (loading) {
    return (
      <div className="text-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary mx-auto"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center mb-6">
        <Phone className="h-6 w-6 text-primary mr-2" />
        <h1 className="text-2xl font-bold">VoIP Instellingen</h1>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      {success && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
          {success}
        </div>
      )}

      <div className="bg-white rounded-lg shadow-md p-6 space-y-6">
        <ServerSettings 
          server={config.server}
          onChange={server => setConfig({ ...config, server })}
          disabled={saving || testing}
        />

        <SIPCredentials
          credentials={config.sipCredentials}
          onChange={sipCredentials => setConfig({ ...config, sipCredentials })}
          disabled={saving || testing}
        />

        <VoIPTestResult 
          testError={testError}
          testSuccess={testSuccess}
        />

        <div className="flex justify-end space-x-4">
          <button
            onClick={handleTest}
            disabled={testing || saving}
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            <PlayCircle className="h-4 w-4 mr-2" />
            {testing ? 'Testen...' : 'Test Verbinding'}
          </button>

          <button
            onClick={handleSave}
            disabled={saving || testing}
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            <Save className="h-4 w-4 mr-2" />
            {saving ? 'Bezig met opslaan...' : 'Instellingen opslaan'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default VoIPSettings;