import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminDashboard from '../components/AdminDashboard';
import GroupManager from '../components/GroupManager';
import UserManagement from '../../../components/admin/UserManagement';
import VoIPSettings from '../components/VoIPSettings';
import SMSSettings from '../components/SMSSettings';
import SecuritySettings from '../components/SecuritySettings';
import EmailTemplates from '../components/EmailTemplates';
import NotificationSettings from '../components/NotificationSettings';
import APISettings from '../components/APISettings';
import GeneralSettings from '../components/GeneralSettings';
import Microsoft365Settings from '../components/Microsoft365Settings';
import NotFound from '../../../components/error/NotFound';

function AdminRoutes() {
  return (
    <Routes>
      <Route index element={<AdminDashboard />} />
      <Route path="users" element={<UserManagement />} />
      <Route path="groups" element={<GroupManager />} />
      <Route path="voip" element={<VoIPSettings />} />
      <Route path="sms" element={<SMSSettings />} />
      <Route path="security" element={<SecuritySettings />} />
      <Route path="email-templates" element={<EmailTemplates />} />
      <Route path="notifications" element={<NotificationSettings />} />
      <Route path="api" element={<APISettings />} />
      <Route path="settings" element={<GeneralSettings />} />
      <Route path="microsoft365" element={<Microsoft365Settings />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AdminRoutes;