import React, { useState } from 'react';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isToday, getWeek, startOfWeek, endOfWeek } from 'date-fns';
import { nl } from 'date-fns/locale';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';
import type { Calendar, CalendarEvent } from '../types';

interface Props {
  events: CalendarEvent[];
  calendars: Calendar[];
  onClose: () => void;
  onDateSelect: (date: Date) => void;
}

function MonthView({ events, calendars, onClose, onDateSelect }: Props) {
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const navigateMonth = (direction: 'prev' | 'next') => {
    setCurrentMonth(current => 
      direction === 'next' 
        ? addMonths(current, 1)
        : subMonths(current, 1)
    );
  };

  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(currentMonth);
  const calendarStart = startOfWeek(monthStart, { locale: nl, weekStartsOn: 1 });
  const calendarEnd = endOfWeek(monthEnd, { locale: nl, weekStartsOn: 1 });
  const daysInMonth = eachDayOfInterval({ start: calendarStart, end: calendarEnd });

  const getEventsForDay = (date: Date) => {
    return events.filter(event => {
      const eventDate = new Date(event.startTime);
      return (
        eventDate.getDate() === date.getDate() &&
        eventDate.getMonth() === date.getMonth() &&
        eventDate.getFullYear() === date.getFullYear()
      );
    });
  };

  const weekDays = ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'];

  return (
    <div className="fixed inset-0 bg-white z-50 overflow-auto">
      <div className="max-w-7xl mx-auto px-4 py-6">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center space-x-4">
            <button
              onClick={() => navigateMonth('prev')}
              className="p-2 rounded-lg hover:bg-gray-100"
            >
              <ChevronLeft className="h-5 w-5" />
            </button>
            
            <h2 className="text-xl font-semibold">
              {format(currentMonth, 'MMMM yyyy', { locale: nl })}
            </h2>
            
            <button
              onClick={() => navigateMonth('next')}
              className="p-2 rounded-lg hover:bg-gray-100"
            >
              <ChevronRight className="h-5 w-5" />
            </button>
          </div>

          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="grid grid-cols-7 gap-px bg-gray-200 rounded-lg overflow-hidden">
          {weekDays.map((day, index) => (
            <div key={`weekday-${index}`} className="bg-gray-50 p-3 text-center font-medium">
              {day}
            </div>
          ))}

          {daysInMonth.map(date => {
            const dayEvents = getEventsForDay(date);
            const isCurrentMonth = isSameMonth(date, currentMonth);
            const weekNumber = getWeek(date, { locale: nl });
            
            return (
              <button
                key={`day-${date.toISOString()}`}
                onClick={() => onDateSelect(date)}
                className={`min-h-[120px] p-3 bg-white hover:bg-gray-50 flex flex-col ${
                  !isCurrentMonth && 'text-gray-400 bg-gray-50'
                } ${isToday(date) && 'bg-blue-50'}`}
              >
                <div className="flex justify-between items-center mb-2">
                  <span className={`text-sm font-medium ${
                    isToday(date) && 'bg-primary text-white px-2 py-0.5 rounded-full'
                  }`}>
                    {format(date, 'd')}
                  </span>
                  <span className="text-xs text-gray-400">
                    week {weekNumber}
                  </span>
                </div>
                <div className="flex-1 space-y-1">
                  {dayEvents.map(event => {
                    const calendar = calendars.find(cal => cal.id === event.calendarId);
                    return (
                      <div
                        key={`event-${event.id}-${event.startTime}`}
                        className="text-left text-xs truncate p-1.5 rounded"
                        style={{ backgroundColor: calendar?.color + '20' }}
                      >
                        <span className="font-medium">
                          {format(new Date(event.startTime), 'HH:mm')}
                        </span>
                        {' '}
                        {event.title}
                      </div>
                    );
                  })}
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MonthView;