import React, { useState } from 'react';
import { Link2, Copy, Check, AlertTriangle, HelpCircle } from 'lucide-react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { configureWebhook } from '../services/webhookService';

interface Props {
  calendarId: string;
  onClose: () => void;
}

function WebhookConfig({ calendarId, onClose }: Props) {
  const [config, setConfig] = useState({
    enabled: false,
    fieldMapping: {
      title: 'title',
      startTime: 'start_time',
      endTime: 'end_time',
      description: 'description',
      location: 'location'
    }
  });
  const [webhookUrl, setWebhookUrl] = useState('');
  const [webhookKey, setWebhookKey] = useState('');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [copied, setCopied] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  React.useEffect(() => {
    loadConfig();
  }, [calendarId]);

  async function loadConfig() {
    try {
      const calendarDoc = await getDoc(doc(db, 'calendars', calendarId));
      if (calendarDoc.exists() && calendarDoc.data().webhook) {
        const webhookData = calendarDoc.data().webhook;
        setConfig(webhookData);
        if (webhookData.key) {
          const baseUrl = window.location.origin;
          setWebhookUrl(`${baseUrl}/api/calendar/webhook/${calendarId}/${webhookData.key}`);
          setWebhookKey(webhookData.key);
        }
      }
    } catch (err) {
      setError('Fout bij het laden van webhook configuratie');
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    
    try {
      setSaving(true);
      setError('');
      setSuccess('');

      const result = await configureWebhook(calendarId, config);
      setWebhookUrl(result.webhookUrl);
      setWebhookKey(result.webhookKey);
      setSuccess('Webhook configuratie opgeslagen');
    } catch (err) {
      setError('Fout bij het opslaan van webhook configuratie');
    } finally {
      setSaving(false);
    }
  }

  const copyWebhookUrl = () => {
    navigator.clipboard.writeText(webhookUrl);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  if (loading) {
    return <div>Laden...</div>;
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-semibold flex items-center">
          <Link2 className="h-5 w-5 mr-2" />
          Zapier Webhook Configuratie
          <button
            onClick={() => setShowHelp(!showHelp)}
            className="ml-2 text-gray-400 hover:text-gray-600"
          >
            <HelpCircle className="h-5 w-5" />
          </button>
        </h3>
      </div>

      {showHelp && (
        <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mb-6">
          <h4 className="font-medium text-blue-800 mb-2">Hoe werkt het?</h4>
          <ol className="list-decimal list-inside text-sm text-blue-700 space-y-2">
            <li>Schakel de webhook in door de checkbox aan te vinken</li>
            <li>Kopieer de webhook URL die verschijnt</li>
            <li>Ga naar Zapier en maak een nieuwe Zap</li>
            <li>Kies je trigger (bijv. "Nieuwe Google Calendar afspraak")</li>
            <li>Voeg een "Webhook" actie toe en kies "POST"</li>
            <li>Plak de gekopieerde webhook URL</li>
            <li>Configureer de veldmapping hieronder zodat het overeenkomt met je Zapier data</li>
          </ol>
        </div>
      )}

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      {success && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
          {success}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex items-center justify-between">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={config.enabled}
              onChange={(e) => setConfig({ ...config, enabled: e.target.checked })}
              className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
            />
            <span className="ml-2 block text-sm text-gray-900">
              Webhook inschakelen
            </span>
          </label>
        </div>

        {config.enabled && (
          <>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Webhook URL
                </label>
                <button
                  type="button"
                  onClick={copyWebhookUrl}
                  className="inline-flex items-center text-sm text-primary hover:text-primary-dark"
                >
                  {copied ? (
                    <Check className="h-4 w-4 mr-1" />
                  ) : (
                    <Copy className="h-4 w-4 mr-1" />
                  )}
                  {copied ? 'Gekopieerd!' : 'Kopieer URL'}
                </button>
              </div>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  value={webhookUrl}
                  readOnly
                  className="flex-1 min-w-0 block w-full px-3 py-2 rounded-md border-gray-300 bg-gray-100 text-gray-500 sm:text-sm"
                />
              </div>
              {webhookKey && (
                <p className="mt-2 text-sm text-gray-500">
                  Webhook Key: {webhookKey}
                </p>
              )}
            </div>

            <div>
              <div className="flex items-center justify-between mb-4">
                <h4 className="text-sm font-medium text-gray-700">
                  Veld Mapping voor Zapier
                </h4>
                <div className="text-sm text-gray-500">
                  Vul hier de veldnamen in zoals ze in Zapier staan
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Titel veld
                    <span className="text-gray-400 ml-1">(verplicht)</span>
                  </label>
                  <input
                    type="text"
                    value={config.fieldMapping.title}
                    onChange={(e) => setConfig({
                      ...config,
                      fieldMapping: {
                        ...config.fieldMapping,
                        title: e.target.value
                      }
                    })}
                    placeholder="Bijv: title of subject"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Start tijd veld
                    <span className="text-gray-400 ml-1">(verplicht)</span>
                  </label>
                  <input
                    type="text"
                    value={config.fieldMapping.startTime}
                    onChange={(e) => setConfig({
                      ...config,
                      fieldMapping: {
                        ...config.fieldMapping,
                        startTime: e.target.value
                      }
                    })}
                    placeholder="Bijv: start_time of start_date"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Eind tijd veld
                    <span className="text-gray-400 ml-1">(verplicht)</span>
                  </label>
                  <input
                    type="text"
                    value={config.fieldMapping.endTime}
                    onChange={(e) => setConfig({
                      ...config,
                      fieldMapping: {
                        ...config.fieldMapping,
                        endTime: e.target.value
                      }
                    })}
                    placeholder="Bijv: end_time of end_date"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Beschrijving veld
                    <span className="text-gray-400 ml-1">(optioneel)</span>
                  </label>
                  <input
                    type="text"
                    value={config.fieldMapping.description}
                    onChange={(e) => setConfig({
                      ...config,
                      fieldMapping: {
                        ...config.fieldMapping,
                        description: e.target.value
                      }
                    })}
                    placeholder="Bijv: description of notes"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Locatie veld
                    <span className="text-gray-400 ml-1">(optioneel)</span>
                  </label>
                  <input
                    type="text"
                    value={config.fieldMapping.location}
                    onChange={(e) => setConfig({
                      ...config,
                      fieldMapping: {
                        ...config.fieldMapping,
                        location: e.target.value
                      }
                    })}
                    placeholder="Bijv: location of address"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                  />
                </div>
              </div>
            </div>

            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <AlertTriangle className="h-5 w-5 text-yellow-400" />
                </div>
                <div className="ml-3">
                  <h4 className="text-sm font-medium text-yellow-800">Belangrijk</h4>
                  <ul className="mt-2 text-sm text-yellow-700 list-disc list-inside">
                    <li>Zorg dat de veldnamen exact overeenkomen met de velden in Zapier</li>
                    <li>Datums moeten in ISO formaat zijn (YYYY-MM-DDTHH:mm:ss)</li>
                    <li>Test de webhook in Zapier voordat je deze in productie gebruikt</li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Sluiten
          </button>
          <button
            type="submit"
            disabled={saving}
            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            {saving ? 'Bezig met opslaan...' : 'Opslaan'}
          </button>
        </div>
      </form>
    </div>
  );
}

export default WebhookConfig;