import React, { useState } from 'react';
import { MessageSquare, Send, History, Settings, BarChart, List, User } from 'lucide-react';
import SMSComposer from './SMSComposer';
import SMSHistory from './SMSHistory';
import SMSSettings from './SMSSettings';
import SMSTemplateManager from './SMSTemplateManager';
import SMSSenderManager from './SMSSenderManager';
import SMSStats from './SMSStats';
import { useAuth } from '../../../contexts/AuthContext';
import { useRoleAccess, ROLES } from '../../../hooks/useRoleAccess';
import type { SMSTemplate } from '../services/templateService';

function SMSDashboard() {
  const { userData } = useAuth();
  const { hasAccess: isAdmin } = useRoleAccess([ROLES.ADMIN, ROLES.SUPERADMIN]);
  const { hasAccess: isSuperAdmin } = useRoleAccess([ROLES.SUPERADMIN]);
  const [activeTab, setActiveTab] = useState('compose');
  const [selectedTemplate, setSelectedTemplate] = useState<SMSTemplate | null>(null);

  const handleTemplateSelect = (template: SMSTemplate) => {
    setSelectedTemplate(template);
    setActiveTab('compose');
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <MessageSquare className="h-6 w-6 text-primary mr-2" />
          <h1 className="text-2xl font-bold">SMS Beheer</h1>
        </div>
      </div>

      <div className="mb-6 border-b border-gray-200">
        <nav className="-mb-px flex space-x-8">
          <button
            onClick={() => setActiveTab('compose')}
            className={`${
              activeTab === 'compose'
                ? 'border-primary text-primary'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
            } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center`}
          >
            <Send className="h-5 w-5 mr-2" />
            Nieuw Bericht
          </button>

          <button
            onClick={() => setActiveTab('templates')}
            className={`${
              activeTab === 'templates'
                ? 'border-primary text-primary'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
            } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center`}
          >
            <List className="h-5 w-5 mr-2" />
            Templates
          </button>

          <button
            onClick={() => setActiveTab('senders')}
            className={`${
              activeTab === 'senders'
                ? 'border-primary text-primary'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
            } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center`}
          >
            <User className="h-5 w-5 mr-2" />
            Afzenders
          </button>

          <button
            onClick={() => setActiveTab('history')}
            className={`${
              activeTab === 'history'
                ? 'border-primary text-primary'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
            } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center`}
          >
            <History className="h-5 w-5 mr-2" />
            Geschiedenis
          </button>

          {isAdmin && (
            <button
              onClick={() => setActiveTab('stats')}
              className={`${
                activeTab === 'stats'
                  ? 'border-primary text-primary'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center`}
            >
              <BarChart className="h-5 w-5 mr-2" />
              Statistieken
            </button>
          )}

          {isSuperAdmin && (
            <button
              onClick={() => setActiveTab('settings')}
              className={`${
                activeTab === 'settings'
                  ? 'border-primary text-primary'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center`}
            >
              <Settings className="h-5 w-5 mr-2" />
              Instellingen
            </button>
          )}
        </nav>
      </div>

      <div className="bg-white rounded-lg shadow-md">
        {activeTab === 'compose' && <SMSComposer initialTemplate={selectedTemplate} />}
        {activeTab === 'templates' && <SMSTemplateManager onSelect={handleTemplateSelect} />}
        {activeTab === 'senders' && <SMSSenderManager />}
        {activeTab === 'history' && <SMSHistory />}
        {activeTab === 'stats' && isAdmin && <SMSStats />}
        {activeTab === 'settings' && isSuperAdmin && <SMSSettings />}
      </div>
    </div>
  );
}

export default SMSDashboard;