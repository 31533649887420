import React from 'react';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import { Clock, MapPin, ExternalLink } from 'lucide-react';

interface Props {
  events: any[];
  calendars: any[];
}

function MobileCalendar({ events, calendars }: Props) {
  const openInGoogleMaps = (address: string) => {
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    window.open(mapsUrl, '_blank');
  };

  if (events.length === 0) {
    return (
      <div className="text-center py-6 text-gray-500">
        Geen afspraken voor vandaag
      </div>
    );
  }

  return (
    <div className="space-y-3">
      {events.map(event => {
        const calendar = calendars.find(cal => cal.id === event.calendarId);
        const isInsezoCalendar = calendar?.provider === 'insezo';
        const address = event.description?.split('\n').join(', ');

        return (
          <div
            key={event.id}
            className="flex items-start p-3 rounded-lg border border-gray-200"
          >
            <div className="flex-shrink-0 flex items-center justify-center w-12 h-12 bg-primary bg-opacity-10 rounded-lg mr-3">
              <Clock className="h-6 w-6 text-primary" />
            </div>
            
            <div className="flex-grow min-w-0">
              <h3 className="font-medium truncate">{event.title}</h3>
              <p className="text-sm text-gray-500">
                {format(new Date(event.startTime), 'HH:mm', { locale: nl })} - 
                {format(new Date(event.endTime), 'HH:mm', { locale: nl })}
              </p>
              {event.location && (
                <div className="flex items-center mt-1">
                  <MapPin className="h-4 w-4 text-gray-400 mr-1" />
                  <p className="text-sm text-gray-500 truncate flex-1">
                    {event.location}
                  </p>
                  {isInsezoCalendar && address && (
                    <button
                      onClick={() => openInGoogleMaps(address)}
                      className="ml-2 p-1 text-primary hover:text-primary-dark rounded-full hover:bg-gray-100"
                    >
                      <ExternalLink className="h-4 w-4" />
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default MobileCalendar;