import React, { useState, useEffect } from 'react';
import { BarChart2 } from 'lucide-react';

interface CallQuality {
  mos: number;
  jitter: number;
  latency: number;
  packetLoss: number;
}

interface Props {
  onQualityChange: (quality: CallQuality) => void;
}

function CallQualityMonitor({ onQualityChange }: Props) {
  const [quality, setQuality] = useState<CallQuality>({
    mos: 0,
    jitter: 0,
    latency: 0,
    packetLoss: 0
  });

  useEffect(() => {
    // Monitor WebRTC stats
    const interval = setInterval(async () => {
      if (window.RTCPeerConnection) {
        // Get WebRTC stats and calculate quality metrics
        const newQuality = {
          mos: calculateMOS(),
          jitter: measureJitter(),
          latency: measureLatency(),
          packetLoss: measurePacketLoss()
        };
        
        setQuality(newQuality);
        onQualityChange(newQuality);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getQualityColor = (mos: number) => {
    if (mos >= 4.0) return 'text-green-500';
    if (mos >= 3.0) return 'text-yellow-500';
    return 'text-red-500';
  };

  return (
    <div className="flex items-center space-x-4">
      <BarChart2 className={`h-5 w-5 ${getQualityColor(quality.mos)}`} />
      <div className="text-sm">
        <div className="font-medium">Gesprekskwaliteit</div>
        <div className="text-gray-500">
          MOS: {quality.mos.toFixed(1)} | 
          Latentie: {quality.latency}ms | 
          Jitter: {quality.jitter}ms | 
          Pakketverlies: {quality.packetLoss}%
        </div>
      </div>
    </div>
  );
}

// Utility functions for calculating metrics
function calculateMOS(): number {
  // Implement MOS calculation based on R-factor
  return 4.5; // Placeholder
}

function measureJitter(): number {
  // Implement jitter measurement
  return 15; // Placeholder
}

function measureLatency(): number {
  // Implement latency measurement
  return 50; // Placeholder
}

function measurePacketLoss(): number {
  // Implement packet loss measurement
  return 0.5; // Placeholder
}

export default CallQualityMonitor;