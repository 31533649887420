import React, { useState, useEffect } from 'react';
import { Activity } from 'lucide-react';

interface Props {
  serverUrl: string;
  onStatusChange: (isHealthy: boolean) => void;
}

function ServerHealthCheck({ serverUrl, onStatusChange }: Props) {
  const [latency, setLatency] = useState<number | null>(null);
  const [isHealthy, setIsHealthy] = useState(true);

  useEffect(() => {
    const checkHealth = async () => {
      try {
        const start = performance.now();
        const response = await fetch(`${serverUrl}/health`);
        const end = performance.now();
        
        if (response.ok) {
          setLatency(Math.round(end - start));
          setIsHealthy(true);
          onStatusChange(true);
        } else {
          setIsHealthy(false);
          onStatusChange(false);
        }
      } catch (err) {
        setIsHealthy(false);
        onStatusChange(false);
      }
    };

    const interval = setInterval(checkHealth, 30000); // Check every 30 seconds
    checkHealth(); // Initial check

    return () => clearInterval(interval);
  }, [serverUrl]);

  return (
    <div className="flex items-center space-x-2">
      <Activity className={`h-4 w-4 ${isHealthy ? 'text-green-500' : 'text-red-500'}`} />
      {latency !== null && isHealthy && (
        <span className="text-sm text-gray-500">
          {latency}ms
        </span>
      )}
    </div>
  );
}

export default ServerHealthCheck;