import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { useAuth } from '../../../contexts/AuthContext';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import { MessageSquare, RefreshCw } from 'lucide-react';

interface SMSMessage {
  id: string;
  recipient: string;
  content: string;
  sentAt: string;
  status: 'sending' | 'sent' | 'failed';
}

function SMSHistory() {
  const { currentUser } = useAuth();
  const [messages, setMessages] = useState<SMSMessage[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (!currentUser) return;

    const messagesQuery = query(
      collection(db, 'sms_messages'),
      where('senderId', '==', currentUser.uid),
      orderBy('sentAt', 'desc')
    );

    const unsubscribe = onSnapshot(messagesQuery, 
      (snapshot) => {
        const newMessages = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as SMSMessage[];
        setMessages(newMessages);
        setLoading(false);
      },
      (err) => {
        setError('Fout bij het laden van verzonden berichten');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [currentUser]);

  const handleRefresh = async () => {
    setRefreshing(true);
    setTimeout(() => setRefreshing(false), 1000);
  };

  if (loading) {
    return <div className="text-center py-8">Berichten laden...</div>;
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold flex items-center">
          <MessageSquare className="h-6 w-6 mr-2" />
          Verzonden Berichten
        </h2>
        <button
          onClick={handleRefresh}
          disabled={refreshing}
          className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-primary bg-primary-50 hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          <RefreshCw className={`h-4 w-4 mr-2 ${refreshing ? 'animate-spin' : ''}`} />
          Vernieuwen
        </button>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <div className="space-y-4">
        {messages.length === 0 ? (
          <div className="text-center py-8 text-gray-500">
            Geen berichten verzonden
          </div>
        ) : (
          messages.map((message) => (
            <div
              key={message.id}
              className="p-4 rounded-lg border bg-gray-50 border-gray-200"
            >
              <div className="flex justify-between items-start mb-2">
                <div className="font-medium">Naar: {message.recipient}</div>
                <div className="text-sm text-gray-500">
                  {format(new Date(message.sentAt), 'PPp', { locale: nl })}
                </div>
              </div>
              <div className="text-gray-700">{message.content}</div>
              <div className="mt-2 text-sm">
                {message.status === 'sending' && (
                  <span className="text-yellow-600">Wordt verzonden...</span>
                )}
                {message.status === 'sent' && (
                  <span className="text-green-600">Verzonden</span>
                )}
                {message.status === 'failed' && (
                  <span className="text-red-600">Verzenden mislukt</span>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default SMSHistory;