import React, { useState, useEffect } from 'react';
import { X, RefreshCw, AlertCircle, CheckCircle, Link2 } from 'lucide-react';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { testCalendarConnection } from '../services/calendarService';
import WebhookConfig from './WebhookConfig';
import type { Calendar } from '../types';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
}

function CalendarSettings({ onClose, onSuccess }: Props) {
  const [users, setUsers] = useState<any[]>([]);
  const [userCalendars, setUserCalendars] = useState<Record<string, Calendar[]>>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [testingCalendar, setTestingCalendar] = useState<string | null>(null);
  const [showWebhookConfig, setShowWebhookConfig] = useState<string | null>(null);
  const [calendarStatuses, setCalendarStatuses] = useState<Record<string, {
    status: 'success' | 'error' | 'testing';
    lastSync?: string;
    lastError?: string;
    eventCount?: number;
  }>>({});

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      setLoading(true);
      setError('');

      // Load users
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const usersData = usersSnapshot.docs
        .filter(doc => doc.data().email) // Filter out invalid users
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .sort((a, b) => a.email.localeCompare(b.email)); // Sort by email
      setUsers(usersData);

      // Load calendars for each user
      const calendarsByUser: Record<string, Calendar[]> = {};
      const initialStatuses: Record<string, any> = {};

      for (const user of usersData) {
        const calendarsQuery = query(
          collection(db, 'calendars'),
          where('ownerId', '==', user.id)
        );
        const calendarsSnapshot = await getDocs(calendarsQuery);
        const calendars = calendarsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Calendar[];

        if (calendars.length > 0) {
          calendarsByUser[user.id] = calendars;

          // Get event counts for each calendar
          for (const calendar of calendars) {
            const eventsQuery = query(
              collection(db, 'calendar_events'),
              where('calendarId', '==', calendar.id)
            );
            const eventsSnapshot = await getDocs(eventsQuery);

            initialStatuses[calendar.id] = {
              status: calendar.syncStatus || 'unknown',
              lastSync: calendar.lastSync,
              lastError: calendar.lastError,
              eventCount: eventsSnapshot.size
            };
          }
        }
      }

      setUserCalendars(calendarsByUser);
      setCalendarStatuses(initialStatuses);
    } catch (err) {
      console.error('Error loading data:', err);
      setError('Er is een fout opgetreden bij het laden van de gegevens');
    } finally {
      setLoading(false);
    }
  }

  const testConnection = async (calendar: Calendar) => {
    try {
      setTestingCalendar(calendar.id);
      setCalendarStatuses(prev => ({
        ...prev,
        [calendar.id]: { ...prev[calendar.id], status: 'testing' }
      }));

      const success = await testCalendarConnection(calendar.id);

      setCalendarStatuses(prev => ({
        ...prev,
        [calendar.id]: {
          ...prev[calendar.id],
          status: success ? 'success' : 'error',
          lastSync: success ? new Date().toISOString() : prev[calendar.id]?.lastSync,
          lastError: success ? undefined : 'Verbinding mislukt'
        }
      }));
    } catch (err) {
      setCalendarStatuses(prev => ({
        ...prev,
        [calendar.id]: {
          ...prev[calendar.id],
          status: 'error',
          lastError: err instanceof Error ? err.message : 'Onbekende fout'
        }
      }));
    } finally {
      setTestingCalendar(null);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (showWebhookConfig) {
    return (
      <WebhookConfig
        calendarId={showWebhookConfig}
        onClose={() => setShowWebhookConfig(null)}
      />
    );
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Agenda Instellingen</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="h-5 w-5" />
          </button>
        </div>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
            {error}
          </div>
        )}

        <div className="space-y-6">
          {users.map(user => (
            <div key={user.id} className="border-b border-gray-200 pb-6">
              <h3 className="font-medium mb-4">{user.email}</h3>
              
              <div className="space-y-3">
                {userCalendars[user.id]?.map(calendar => (
                  <div
                    key={calendar.id}
                    className="flex items-center justify-between p-4 rounded-lg border border-gray-200"
                  >
                    <div className="flex items-center">
                      <div
                        className="w-4 h-4 rounded-full mr-3"
                        style={{ backgroundColor: calendar.color }}
                      />
                      <div>
                        <h4 className="font-medium">{calendar.name}</h4>
                        <p className="text-sm text-gray-500">{calendar.type}</p>
                        <p className="text-sm text-gray-500">
                          Laatste synchronisatie: {calendarStatuses[calendar.id]?.lastSync ? 
                            new Date(calendarStatuses[calendar.id].lastSync).toLocaleString('nl-NL') : 
                            'Nooit'
                          }
                        </p>
                        <p className="text-sm text-gray-500">
                          Aantal afspraken: {calendarStatuses[calendar.id]?.eventCount || 0}
                        </p>
                        {calendar.url && (
                          <p className="text-sm text-gray-500 truncate max-w-md">
                            URL: {calendar.url}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="flex items-center space-x-4">
                      {calendarStatuses[calendar.id]?.status === 'error' && (
                        <div className="flex items-center text-red-600">
                          <AlertCircle className="h-5 w-5 mr-1" />
                          <span className="text-sm">
                            {calendarStatuses[calendar.id]?.lastError || 'Fout'}
                          </span>
                        </div>
                      )}

                      {calendarStatuses[calendar.id]?.status === 'success' && (
                        <div className="flex items-center text-green-600">
                          <CheckCircle className="h-5 w-5 mr-1" />
                          <span className="text-sm">Verbonden</span>
                        </div>
                      )}

                      <button
                        onClick={() => setShowWebhookConfig(calendar.id)}
                        className="flex items-center px-3 py-1 text-sm text-primary hover:text-primary-dark"
                      >
                        <Link2 className="h-4 w-4 mr-1" />
                        Webhook
                      </button>

                      <button
                        onClick={() => testConnection(calendar)}
                        disabled={testingCalendar === calendar.id}
                        className="flex items-center px-3 py-1 text-sm text-primary hover:text-primary-dark disabled:opacity-50"
                      >
                        <RefreshCw className={`h-4 w-4 mr-1 ${
                          testingCalendar === calendar.id ? 'animate-spin' : ''
                        }`} />
                        Test Verbinding
                      </button>
                    </div>
                  </div>
                ))}

                {(!userCalendars[user.id] || userCalendars[user.id].length === 0) && (
                  <p className="text-sm text-gray-500">
                    Geen agenda's gevonden
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CalendarSettings;