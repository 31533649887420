import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { Plus, Edit, Trash2, Save, X } from 'lucide-react';
import { createTemplate, updateTemplate, deleteTemplate, getTemplates } from '../services/templateService';
import type { SMSTemplate } from '../services/templateService';

const CATEGORIES = [
  { id: 'service', name: 'Service' },
  { id: 'marketing', name: 'Marketing' },
  { id: 'herinnering', name: 'Herinnering' },
  { id: 'notificatie', name: 'Notificatie' },
  { id: 'algemeen', name: 'Algemeen' }
];

function SMSTemplateManager() {
  const { currentUser } = useAuth();
  const [templates, setTemplates] = useState<SMSTemplate[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    name: '',
    content: '',
    category: 'algemeen'
  });

  useEffect(() => {
    loadTemplates();
  }, []);

  async function loadTemplates() {
    try {
      const loadedTemplates = await getTemplates();
      setTemplates(loadedTemplates);
    } catch (err) {
      setError('Fout bij het laden van templates');
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    
    try {
      setError('');
      setSuccess('');

      if (editingId) {
        const template = templates.find(t => t.id === editingId);
        if (template?.isDefault) {
          setError('Standaard templates kunnen niet worden bewerkt');
          return;
        }

        await updateTemplate(editingId, formData);
        setSuccess('Template succesvol bijgewerkt');
      } else {
        await createTemplate({
          ...formData,
          createdBy: currentUser!.uid,
          isDefault: false
        });
        setSuccess('Template succesvol toegevoegd');
      }

      setFormData({ name: '', content: '', category: 'algemeen' });
      setShowAddForm(false);
      setEditingId(null);
      loadTemplates();
    } catch (err) {
      setError('Fout bij het opslaan van template');
    }
  }

  async function handleDelete(id: string) {
    try {
      const template = templates.find(t => t.id === id);
      if (template?.isDefault) {
        setError('Standaard templates kunnen niet worden verwijderd');
        return;
      }

      await deleteTemplate(id);
      setSuccess('Template succesvol verwijderd');
      loadTemplates();
    } catch (err) {
      setError('Fout bij het verwijderen van template');
    }
  }

  if (loading) {
    return <div>Laden...</div>;
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold">SMS Templates</h2>
        {!showAddForm && (
          <button
            onClick={() => setShowAddForm(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            <Plus className="h-5 w-5 mr-2" />
            Nieuwe Template
          </button>
        )}
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      {success && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
          {success}
        </div>
      )}

      {showAddForm && (
        <form onSubmit={handleSubmit} className="mb-6 bg-gray-50 p-4 rounded-lg">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Template Naam
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Categorie
              </label>
              <select
                value={formData.category}
                onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
              >
                {CATEGORIES.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Bericht Content
              </label>
              <textarea
                value={formData.content}
                onChange={(e) => setFormData({ ...formData, content: e.target.value })}
                rows={4}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                required
              />
              <p className="mt-1 text-sm text-gray-500">
                Gebruik {'{variabele}'} voor dynamische waardes
              </p>
            </div>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => {
                  setShowAddForm(false);
                  setEditingId(null);
                  setFormData({ name: '', content: '', category: 'algemeen' });
                }}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                <X className="h-5 w-5 mr-2" />
                Annuleren
              </button>
              <button
                type="submit"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                <Save className="h-5 w-5 mr-2" />
                {editingId ? 'Bijwerken' : 'Opslaan'}
              </button>
            </div>
          </div>
        </form>
      )}

      <div className="space-y-4">
        {templates.map(template => (
          <div
            key={template.id}
            className="bg-white p-4 rounded-lg border border-gray-200"
          >
            <div className="flex justify-between items-start">
              <div>
                <div className="flex items-center space-x-2">
                  <h3 className="font-medium">{template.name}</h3>
                  {template.isDefault && (
                    <span className="px-2 py-1 text-xs bg-blue-100 text-blue-800 rounded">
                      Standaard
                    </span>
                  )}
                  <span className="px-2 py-1 text-xs bg-gray-100 text-gray-800 rounded">
                    {CATEGORIES.find(c => c.id === template.category)?.name || template.category}
                  </span>
                </div>
                <p className="text-gray-600 mt-2">{template.content}</p>
              </div>
              {!template.isDefault && (
                <div className="flex space-x-2">
                  <button
                    onClick={() => {
                      setFormData({
                        name: template.name,
                        content: template.content,
                        category: template.category
                      });
                      setEditingId(template.id);
                      setShowAddForm(true);
                    }}
                    className="text-blue-600 hover:text-blue-800"
                  >
                    <Edit className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handleDelete(template.id)}
                    className="text-red-600 hover:text-red-800"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}

        {templates.length === 0 && (
          <p className="text-gray-500 text-center py-4">
            Nog geen templates beschikbaar
          </p>
        )}
      </div>
    </div>
  );
}

export default SMSTemplateManager;