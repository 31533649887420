import { useState, useEffect } from 'react';

interface AudioDeviceState {
  outputDevice: string;
  inputDevice: string;
  volume: number;
}

export function useAudioDevices() {
  const [devices, setDevices] = useState<AudioDeviceState>({
    outputDevice: 'default',
    inputDevice: 'default',
    volume: 1.0
  });

  const setAudioDevice = async (deviceId: string, kind: MediaDeviceKind) => {
    try {
      if (kind === 'audiooutput') {
        // @ts-ignore - setSinkId is nog niet in alle TypeScript definities
        await (document.getElementById('remoteAudio') as HTMLAudioElement)?.setSinkId(deviceId);
        setDevices(prev => ({ ...prev, outputDevice: deviceId }));
      } else {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: { exact: deviceId } }
        });
        // Stop oude tracks
        stream.getTracks().forEach(track => track.stop());
        setDevices(prev => ({ ...prev, inputDevice: deviceId }));
      }
    } catch (err) {
      console.error('Fout bij het wijzigen van audio apparaat:', err);
      throw err;
    }
  };

  const setVolume = (volume: number) => {
    const audio = document.getElementById('remoteAudio') as HTMLAudioElement;
    if (audio) {
      audio.volume = Math.max(0, Math.min(1, volume));
      setDevices(prev => ({ ...prev, volume }));
    }
  };

  return {
    devices,
    setAudioDevice,
    setVolume
  };
}