import { useEffect, useState } from 'react';
import { useUserData } from './useUserData';

export const ROLES = {
  SUPERADMIN: 'superadmin',
  ADMIN: 'admin',
  USER: 'user'
} as const;

export type Role = typeof ROLES[keyof typeof ROLES];

export const PERMISSIONS = {
  // Gebruikersbeheer
  MANAGE_USERS: 'manage_users',           
  MANAGE_ROLES: 'manage_roles',           
  VIEW_USERS: 'view_users',               
  
  // Groepsbeheer
  MANAGE_GROUPS: 'manage_groups',         
  VIEW_GROUPS: 'view_groups',             
  
  // VoIP
  MANAGE_VOIP: 'manage_voip',            
  USE_VOIP: 'use_voip',                  
  VIEW_CALL_HISTORY: 'view_call_history', 
  
  // SMS
  SEND_MESSAGES: 'send_messages',         
  MANAGE_TEMPLATES: 'manage_templates',    
  VIEW_SMS_HISTORY: 'view_sms_history',   
  
  // Thema & Instellingen
  MANAGE_THEMES: 'manage_themes',         
  MANAGE_SETTINGS: 'manage_settings',     
  
  // Rapportage
  VIEW_ANALYTICS: 'view_analytics',       
  EXPORT_DATA: 'export_data'             
} as const;

export type Permission = typeof PERMISSIONS[keyof typeof PERMISSIONS];

const ROLE_PERMISSIONS: Record<Role, Permission[]> = {
  [ROLES.SUPERADMIN]: Object.values(PERMISSIONS),
  [ROLES.ADMIN]: [
    PERMISSIONS.VIEW_USERS,
    PERMISSIONS.MANAGE_USERS,
    PERMISSIONS.VIEW_GROUPS,
    PERMISSIONS.MANAGE_GROUPS,
    PERMISSIONS.USE_VOIP,
    PERMISSIONS.MANAGE_VOIP,
    PERMISSIONS.VIEW_CALL_HISTORY,
    PERMISSIONS.SEND_MESSAGES,
    PERMISSIONS.MANAGE_TEMPLATES,
    PERMISSIONS.VIEW_SMS_HISTORY,
    PERMISSIONS.VIEW_ANALYTICS,
    PERMISSIONS.EXPORT_DATA
  ],
  [ROLES.USER]: [
    PERMISSIONS.USE_VOIP,
    PERMISSIONS.VIEW_CALL_HISTORY,
    PERMISSIONS.SEND_MESSAGES,
    PERMISSIONS.VIEW_SMS_HISTORY,
    PERMISSIONS.VIEW_GROUPS
  ]
};

export function useRoleAccess(requiredRoles: Role[] = [], requiredPermissions: Permission[] = []) {
  const { userData, loading, error } = useUserData();
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    if (!loading && userData) {
      const userRole = userData.role as Role;

      // Als er geen vereiste rollen of permissies zijn, geef toegang
      if (requiredRoles.length === 0 && requiredPermissions.length === 0) {
        setHasAccess(true);
        return;
      }

      // Superadmin heeft altijd toegang
      if (userRole === ROLES.SUPERADMIN) {
        setHasAccess(true);
        return;
      }

      // Controleer rol toegang als er rollen zijn gespecificeerd
      const hasRoleAccess = requiredRoles.length === 0 || requiredRoles.includes(userRole);

      // Controleer permissie toegang als er permissies zijn gespecificeerd
      const userPermissions = ROLE_PERMISSIONS[userRole] || [];
      const hasPermissionAccess = requiredPermissions.length === 0 || 
        requiredPermissions.every(permission => userPermissions.includes(permission));

      setHasAccess(hasRoleAccess && hasPermissionAccess);
    } else {
      setHasAccess(false);
    }
  }, [userData, loading, requiredRoles, requiredPermissions]);

  return { 
    hasAccess, 
    loading, 
    error,
    userRole: userData?.role as Role | undefined,
    permissions: userData ? ROLE_PERMISSIONS[userData.role as Role] || [] : []
  };
}