import React from 'react';
import { Mail } from 'lucide-react';
import { useRoleAccess, ROLES } from '../../../hooks/useRoleAccess';
import { Navigate } from 'react-router-dom';

function EmailTemplates() {
  const { hasAccess } = useRoleAccess([ROLES.SUPERADMIN]);

  if (!hasAccess) {
    return <Navigate to="/admin" replace />;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center mb-6">
        <Mail className="h-6 w-6 text-primary mr-2" />
        <h1 className="text-2xl font-bold">E-mail Templates</h1>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6">
        <p className="text-gray-600 mb-4">
          E-mail templates worden beheerd via Firebase Authentication. Ga naar de Firebase Console om de templates aan te passen.
        </p>
        
        <a 
          href="https://console.firebase.google.com" 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark"
        >
          Open Firebase Console
        </a>
      </div>
    </div>
  );
}

export default EmailTemplates;