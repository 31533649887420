import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Users, Plus, Edit, Trash2, UserPlus } from 'lucide-react';
import { collection, getDocs, addDoc, deleteDoc, doc, query, where } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { useRoleAccess, ROLES } from '../../../hooks/useRoleAccess';

interface Group {
  id: string;
  name: string;
  description?: string;
  createdAt: string;
  ownerId: string;
}

interface User {
  id: string;
  email: string;
  companyName: string;
  role: string;
}

interface GroupMember {
  id: string;
  userId: string;
  groupId: string;
  role: 'owner' | 'admin' | 'member';
  user?: User;
}

function GroupManager() {
  const navigate = useNavigate();
  const { hasAccess } = useRoleAccess([ROLES.ADMIN, ROLES.SUPERADMIN]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [groupMembers, setGroupMembers] = useState<GroupMember[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [newGroup, setNewGroup] = useState({ name: '', description: '' });
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedRole, setSelectedRole] = useState<'admin' | 'member'>('member');

  useEffect(() => {
    if (hasAccess) {
      loadGroups();
      loadUsers();
    }
  }, [hasAccess]);

  useEffect(() => {
    if (selectedGroup) {
      loadGroupMembers(selectedGroup);
    }
  }, [selectedGroup]);

  async function loadGroups() {
    try {
      const groupsSnapshot = await getDocs(collection(db, 'groups'));
      const groupsData = groupsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Group[];
      setGroups(groupsData);
    } catch (err) {
      setError('Fout bij het laden van groepen');
    } finally {
      setLoading(false);
    }
  }

  async function loadUsers() {
    try {
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const usersData = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as User[];
      setUsers(usersData);
    } catch (err) {
      setError('Fout bij het laden van gebruikers');
    }
  }

  async function loadGroupMembers(groupId: string) {
    try {
      const membersQuery = query(
        collection(db, 'group_members'),
        where('groupId', '==', groupId)
      );
      const membersSnapshot = await getDocs(membersQuery);
      const membersData = membersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as GroupMember[];

      // Load user data for each member
      const membersWithUserData = await Promise.all(
        membersData.map(async member => {
          const userDoc = await getDocs(
            query(collection(db, 'users'), where('id', '==', member.userId))
          );
          const userData = userDoc.docs[0]?.data() as User;
          return { ...member, user: userData };
        })
      );

      setGroupMembers(membersWithUserData);
    } catch (err) {
      setError('Fout bij het laden van groepsleden');
    }
  }

  async function handleAddGroup(e: React.FormEvent) {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, 'groups'), {
        ...newGroup,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });

      setShowAddModal(false);
      setNewGroup({ name: '', description: '' });
      loadGroups();
      setSelectedGroup(docRef.id);
    } catch (err) {
      setError('Fout bij het toevoegen van groep');
    }
  }

  async function handleAddMember(e: React.FormEvent) {
    e.preventDefault();
    if (!selectedGroup || !selectedUser) return;

    try {
      await addDoc(collection(db, 'group_members'), {
        groupId: selectedGroup,
        userId: selectedUser,
        role: selectedRole,
        joinedAt: new Date().toISOString()
      });
      setShowAddMemberModal(false);
      setSelectedUser('');
      setSelectedRole('member');
      loadGroupMembers(selectedGroup);
    } catch (err) {
      setError('Fout bij het toevoegen van groepslid');
    }
  }

  async function handleDeleteGroup(groupId: string) {
    if (!window.confirm('Weet u zeker dat u deze groep wilt verwijderen?')) {
      return;
    }

    try {
      await deleteDoc(doc(db, 'groups', groupId));
      if (selectedGroup === groupId) {
        setSelectedGroup(null);
      }
      loadGroups();
    } catch (err) {
      setError('Fout bij het verwijderen van groep');
    }
  }

  async function handleRemoveMember(memberId: string) {
    if (!window.confirm('Weet u zeker dat u dit lid wilt verwijderen?')) {
      return;
    }

    try {
      await deleteDoc(doc(db, 'group_members', memberId));
      if (selectedGroup) {
        loadGroupMembers(selectedGroup);
      }
    } catch (err) {
      setError('Fout bij het verwijderen van groepslid');
    }
  }

  if (loading) {
    return <div className="text-center py-8">Laden...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center">
          <Users className="h-6 w-6 text-primary mr-2" />
          <h1 className="text-2xl font-bold">Groepsbeheer</h1>
        </div>
        <button
          onClick={() => setShowAddModal(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark"
        >
          <Plus className="h-5 w-5 mr-2" />
          Nieuwe Groep
        </button>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Groups List */}
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <div className="px-4 py-3 border-b border-gray-200">
            <h2 className="text-lg font-semibold">Groepen</h2>
          </div>
          <div className="divide-y divide-gray-200">
            {groups.map(group => (
              <div
                key={group.id}
                className={`p-4 hover:bg-gray-50 cursor-pointer ${
                  selectedGroup === group.id ? 'bg-blue-50' : ''
                }`}
                onClick={() => setSelectedGroup(group.id)}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-medium">{group.name}</h3>
                    {group.description && (
                      <p className="text-sm text-gray-500">{group.description}</p>
                    )}
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/admin/groups/${group.id}/edit`);
                      }}
                      className="text-primary hover:text-primary-dark"
                    >
                      <Edit className="h-5 w-5" />
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteGroup(group.id);
                      }}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Group Members */}
        {selectedGroup && (
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="px-4 py-3 border-b border-gray-200 flex justify-between items-center">
              <h2 className="text-lg font-semibold">Groepsleden</h2>
              <button
                onClick={() => setShowAddMemberModal(true)}
                className="inline-flex items-center px-3 py-1 border border-transparent rounded-md text-sm font-medium text-white bg-primary hover:bg-primary-dark"
              >
                <UserPlus className="h-4 w-4 mr-1" />
                Lid Toevoegen
              </button>
            </div>
            <div className="divide-y divide-gray-200">
              {groupMembers.map(member => (
                <div key={member.id} className="p-4">
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="font-medium">{member.user?.email}</div>
                      <div className="text-sm text-gray-500">
                        {member.role.charAt(0).toUpperCase() + member.role.slice(1)}
                      </div>
                    </div>
                    <button
                      onClick={() => handleRemoveMember(member.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Add Group Modal */}
      {showAddModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h2 className="text-lg font-semibold mb-4">Nieuwe Groep</h2>
            <form onSubmit={handleAddGroup}>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Naam
                  </label>
                  <input
                    type="text"
                    value={newGroup.name}
                    onChange={(e) => setNewGroup({ ...newGroup, name: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Beschrijving
                  </label>
                  <textarea
                    value={newGroup.description}
                    onChange={(e) => setNewGroup({ ...newGroup, description: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                    rows={3}
                  />
                </div>
                <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={() => setShowAddModal(false)}
                    className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Annuleren
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark"
                  >
                    Toevoegen
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Add Member Modal */}
      {showAddMemberModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h2 className="text-lg font-semibold mb-4">Lid Toevoegen</h2>
            <form onSubmit={handleAddMember}>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Gebruiker
                  </label>
                  <select
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                    required
                  >
                    <option value="">Selecteer een gebruiker...</option>
                    {users.map(user => (
                      <option key={user.id} value={user.id}>
                        {user.email} ({user.companyName})
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Rol
                  </label>
                  <select
                    value={selectedRole}
                    onChange={(e) => setSelectedRole(e.target.value as 'admin' | 'member')}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                    required
                  >
                    <option value="member">Lid</option>
                    <option value="admin">Beheerder</option>
                  </select>
                </div>
                <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={() => setShowAddMemberModal(false)}
                    className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Annuleren
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark"
                  >
                    Toevoegen
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default GroupManager;