import React, { useEffect } from 'react';
import { useVoIP } from '../hooks/useVoIP';

interface Props {
  primaryServer: string;
  alternativeServer: string;
  onSwitch: (server: 'primary' | 'alternative') => void;
}

function AutoServerSwitch({ primaryServer, alternativeServer, onSwitch }: Props) {
  const { status, error } = useVoIP();

  useEffect(() => {
    let failedAttempts = 0;
    let currentServer: 'primary' | 'alternative' = 'primary';

    const handleServerFailure = () => {
      failedAttempts++;
      
      if (failedAttempts >= 3) {
        currentServer = currentServer === 'primary' ? 'alternative' : 'primary';
        failedAttempts = 0;
        onSwitch(currentServer);
      }
    };

    if (status === 'error' || error) {
      handleServerFailure();
    }
  }, [status, error]);

  return null; // This is a utility component, no UI needed
}

export default AutoServerSwitch;