import { collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../../services/firebase';

export interface SendMessageParams {
  recipient: string | string[];
  content: string;
  senderId: string;
  sender: string;
  templateId?: string;
}

class SMSService {
  private readonly config = {
    clientId: '736817346436349198312',
    clientSecret: 'QUVdvUTsvLqwrkOOhSbgR',
    apiUrl: 'https://api.smsgatewayapi.com/v1/message/send'
  };

  private validatePhoneNumber(number: string): boolean {
    const cleanNumber = number.replace(/[\s-]/g, '');
    const phoneRegex = /^(\+31|0|31)?([1-9][0-9]{8})$/;
    
    if (!phoneRegex.test(cleanNumber)) {
      return false;
    }

    let internationalNumber = cleanNumber;
    if (cleanNumber.startsWith('0')) {
      internationalNumber = '31' + cleanNumber.substring(1);
    } else if (!cleanNumber.startsWith('31')) {
      internationalNumber = '31' + cleanNumber;
    }

    return internationalNumber.length === 11;
  }

  private formatPhoneNumber(number: string): string {
    const cleanNumber = number.replace(/[\s-]/g, '');
    if (cleanNumber.startsWith('0')) {
      return '31' + cleanNumber.substring(1);
    }
    if (!cleanNumber.startsWith('31')) {
      return '31' + cleanNumber;
    }
    return cleanNumber;
  }

  async sendMessage(params: SendMessageParams): Promise<any> {
    try {
      // Validate required fields
      if (!params.recipient || !params.content || !params.senderId || !params.sender) {
        throw new Error('Alle verplichte velden moeten worden ingevuld');
      }

      // Validate and format phone numbers
      const recipients = Array.isArray(params.recipient) ? params.recipient : [params.recipient];
      const formattedRecipients = recipients.map(number => {
        if (!this.validatePhoneNumber(number)) {
          throw new Error(`Ongeldig telefoonnummer: ${number}`);
        }
        return this.formatPhoneNumber(number);
      });

      // Create message record
      const messageDoc = await addDoc(collection(db, 'sms_messages'), {
        recipient: formattedRecipients,
        content: params.content,
        senderId: params.senderId,
        sender: params.sender,
        status: 'sending',
        createdAt: new Date().toISOString()
      });

      // Send message via API
      const response = await fetch(this.config.apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Client-Id': this.config.clientId,
          'X-Client-Secret': this.config.clientSecret
        },
        body: JSON.stringify({
          message: params.content,
          to: formattedRecipients,
          sender: params.sender
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `SMS Gateway error: ${response.status}`);
      }

      const result = await response.json();

      // Update message status
      await updateDoc(doc(db, 'sms_messages', messageDoc.id), {
        status: 'sent',
        sentAt: new Date().toISOString(),
        providerResponse: result
      });

      return {
        success: true,
        messageId: messageDoc.id,
        providerResponse: result
      };

    } catch (error) {
      console.error('Error sending SMS:', error);
      throw error instanceof Error ? error : new Error('Onbekende fout bij verzenden SMS');
    }
  }
}

export const smsService = new SMSService();