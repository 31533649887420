import React from 'react';
import { Shield, Key, Users } from 'lucide-react';
import { ROLES } from '../../../hooks/useRoleAccess';
import { User } from './types';

interface Props {
  users: User[];
  groups: any[];
  userGroups: Record<string, string[]>;
  hasAccess: boolean;
  onRoleChange: (userId: string, newRole: string) => void;
  onChangePassword: (user: User) => void;
  onManageGroups: (user: User) => void;
}

function UserList({ 
  users, 
  groups, 
  userGroups, 
  hasAccess, 
  onRoleChange, 
  onChangePassword,
  onManageGroups 
}: Props) {
  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Bedrijf
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              E-mail
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Rol
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Groepen
            </th>
            {hasAccess && (
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Acties
              </th>
            )}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {users.map(user => (
            <tr key={user.id} className="hover:bg-gray-50">
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900">
                  {user.companyName}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-500">{user.email}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                  ${user.role === ROLES.SUPERADMIN ? 'bg-purple-100 text-purple-800' :
                    user.role === ROLES.ADMIN ? 'bg-blue-100 text-blue-800' :
                    'bg-green-100 text-green-800'}`}>
                  <Shield className="h-3 w-3 mr-1" />
                  {user.role}
                </span>
              </td>
              <td className="px-6 py-4">
                <div className="text-sm text-gray-500">
                  {userGroups[user.id]?.map(groupId => {
                    const group = groups.find(g => g.id === groupId);
                    return group ? (
                      <span key={groupId} className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 mr-1 mb-1">
                        {group.name}
                      </span>
                    ) : null;
                  })}
                  <button
                    onClick={() => onManageGroups(user)}
                    className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium text-primary hover:text-primary-dark ml-1"
                  >
                    <Users className="h-3 w-3 mr-1" />
                    Beheer
                  </button>
                </div>
              </td>
              {hasAccess && (
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <div className="flex items-center space-x-4">
                    <select
                      value={user.role}
                      onChange={(e) => onRoleChange(user.id, e.target.value)}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                    >
                      <option value={ROLES.USER}>Gebruiker</option>
                      <option value={ROLES.ADMIN}>Beheerder</option>
                      <option value={ROLES.SUPERADMIN}>Superbeheerder</option>
                    </select>
                    
                    <button
                      onClick={() => onChangePassword(user)}
                      className="text-primary hover:text-primary-dark"
                      title="Wachtwoord wijzigen"
                    >
                      <Key className="h-5 w-5" />
                    </button>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UserList;