import React, { useState, useEffect } from 'react';
import { Calendar, Check, X, Loader } from 'lucide-react';
import { initializeMsal, getMicrosoftAuthToken } from '../../../services/microsoftCalendar';

function MicrosoftCalendarConnect() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        await initializeMsal();
        // Check if we already have an active account
        const msalInstance = await initializeMsal();
        if (msalInstance?.getAllAccounts().length > 0) {
          setConnected(true);
        }
      } catch (err) {
        console.error('MSAL init error:', err);
        setError('Fout bij het initialiseren van Microsoft login');
      } finally {
        setLoading(false);
      }
    };

    init();
  }, []);

  const handleConnect = async () => {
    try {
      setLoading(true);
      setError('');
      
      await getMicrosoftAuthToken();
      setConnected(true);
      
    } catch (err: any) {
      console.error('Microsoft auth error:', err);
      setError(err.message || 'Fout bij het verbinden met Microsoft 365');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center justify-center">
          <Loader className="h-6 w-6 animate-spin text-primary" />
          <span className="ml-2">Laden...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <Calendar className="h-5 w-5 text-primary mr-2" />
          <h3 className="text-lg font-medium">Microsoft 365 Agenda</h3>
        </div>
        
        {connected ? (
          <div className="flex items-center text-green-600">
            <Check className="h-5 w-5 mr-1" />
            <span>Verbonden met Microsoft 365</span>
          </div>
        ) : (
          <button
            onClick={handleConnect}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            Verbind met Microsoft 365
          </button>
        )}
      </div>

      {error && (
        <div className="flex items-center text-red-600 mt-2">
          <X className="h-5 w-5 mr-1" />
          <span>{error}</span>
        </div>
      )}

      {connected && (
        <p className="text-sm text-gray-600 mt-2">
          Je Microsoft 365 agenda is nu gekoppeld. Nieuwe afspraken worden automatisch gesynchroniseerd.
        </p>
      )}
    </div>
  );
}

export default MicrosoftCalendarConnect;