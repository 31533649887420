import { collection, query, getDocs, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../services/firebase';

export interface SMSSender {
  id: string;
  name: string;
  value: string;
  isDefault: boolean;
  createdAt: string;
  updatedAt?: string;
}

export async function getSenders() {
  try {
    const sendersQuery = query(collection(db, 'sms_senders'));
    const snapshot = await getDocs(sendersQuery);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as SMSSender[];
  } catch (error) {
    console.error('Error loading senders:', error);
    throw new Error('Fout bij het laden van afzenders');
  }
}

export async function createSender(sender: Omit<SMSSender, 'id' | 'createdAt'>) {
  try {
    // Validate sender value format
    if (!/^[a-zA-Z0-9]{3,11}$/.test(sender.value)) {
      throw new Error('Afzender mag alleen letters en cijfers bevatten (3-11 tekens)');
    }

    const docRef = await addDoc(collection(db, 'sms_senders'), {
      ...sender,
      createdAt: new Date().toISOString()
    });
    return docRef.id;
  } catch (error) {
    console.error('Error creating sender:', error);
    throw error instanceof Error ? error : new Error('Fout bij het aanmaken van afzender');
  }
}

export async function updateSender(id: string, sender: Partial<SMSSender>) {
  try {
    await updateDoc(doc(db, 'sms_senders', id), {
      ...sender,
      updatedAt: new Date().toISOString()
    });
  } catch (error) {
    console.error('Error updating sender:', error);
    throw new Error('Fout bij het bijwerken van afzender');
  }
}

export async function deleteSender(id: string) {
  try {
    await deleteDoc(doc(db, 'sms_senders', id));
  } catch (error) {
    console.error('Error deleting sender:', error);
    throw new Error('Fout bij het verwijderen van afzender');
  }
}