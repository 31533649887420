import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, updateDoc, doc, where } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { useRoleAccess, ROLES } from '../../../hooks/useRoleAccess';
import Header from './Header';
import UserList from './UserList';
import LoadingState from './LoadingState';
import ErrorMessage from './ErrorMessage';
import AddUserModal from './AddUserModal';
import ChangePasswordModal from './ChangePasswordModal';
import ManageGroupsModal from './ManageGroupsModal';
import { User } from './types';
import { UserPlus } from 'lucide-react';

function UserManagement() {
  const [users, setUsers] = useState<User[]>([]);
  const [groups, setGroups] = useState<any[]>([]);
  const [userGroups, setUserGroups] = useState<Record<string, string[]>>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showGroupsModal, setShowGroupsModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const { hasAccess } = useRoleAccess([ROLES.SUPERADMIN]);

  useEffect(() => {
    fetchUsers();
    fetchGroups();
    fetchUserGroups();
  }, []);

  async function fetchUsers() {
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef);
      const querySnapshot = await getDocs(q);
      
      const usersData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as User[];
      
      setUsers(usersData);
    } catch (err) {
      setError('Er is een fout opgetreden bij het ophalen van gebruikers.');
    }
  }

  async function fetchGroups() {
    try {
      const groupsSnapshot = await getDocs(collection(db, 'groups'));
      const groupsData = groupsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setGroups(groupsData);
    } catch (err) {
      setError('Er is een fout opgetreden bij het ophalen van groepen.');
    }
  }

  async function fetchUserGroups() {
    try {
      const membershipsSnapshot = await getDocs(collection(db, 'group_members'));
      const userGroupsMap: Record<string, string[]> = {};
      
      membershipsSnapshot.docs.forEach(doc => {
        const data = doc.data();
        if (!userGroupsMap[data.userId]) {
          userGroupsMap[data.userId] = [];
        }
        userGroupsMap[data.userId].push(data.groupId);
      });
      
      setUserGroups(userGroupsMap);
    } catch (err) {
      setError('Er is een fout opgetreden bij het ophalen van groepslidmaatschappen.');
    } finally {
      setLoading(false);
    }
  }

  async function handleRoleChange(userId: string, newRole: string) {
    if (!hasAccess) {
      setError('U heeft geen rechten om rollen te wijzigen.');
      return;
    }

    try {
      await updateDoc(doc(db, 'users', userId), {
        role: newRole,
        updatedAt: new Date().toISOString()
      });
      
      setUsers(prevUsers =>
        prevUsers.map(user =>
          user.id === userId ? { ...user, role: newRole } : user
        )
      );
    } catch (err) {
      setError('Er is een fout opgetreden bij het wijzigen van de rol.');
    }
  }

  const handleChangePassword = (user: User) => {
    setSelectedUser(user);
    setShowPasswordModal(true);
  };

  const handleManageGroups = (user: User) => {
    setSelectedUser(user);
    setShowGroupsModal(true);
  };

  const handleGroupsUpdated = () => {
    fetchUserGroups();
    setShowGroupsModal(false);
  };

  if (loading) {
    return <LoadingState />;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <Header hasAccess={hasAccess} />
        {hasAccess && (
          <button
            onClick={() => setShowAddModal(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            <UserPlus className="h-5 w-5 mr-2" />
            Gebruiker Toevoegen
          </button>
        )}
      </div>

      {error && <ErrorMessage message={error} />}
      
      <UserList 
        users={users}
        groups={groups}
        userGroups={userGroups}
        hasAccess={hasAccess}
        onRoleChange={handleRoleChange}
        onChangePassword={handleChangePassword}
        onManageGroups={handleManageGroups}
      />

      {showAddModal && (
        <AddUserModal
          onClose={() => setShowAddModal(false)}
          onSuccess={() => {
            setShowAddModal(false);
            fetchUsers();
            fetchUserGroups();
          }}
        />
      )}

      {showPasswordModal && selectedUser && (
        <ChangePasswordModal
          user={selectedUser}
          onClose={() => {
            setShowPasswordModal(false);
            setSelectedUser(null);
          }}
        />
      )}

      {showGroupsModal && selectedUser && (
        <ManageGroupsModal
          user={selectedUser}
          groups={groups}
          currentGroups={userGroups[selectedUser.id] || []}
          onClose={() => {
            setShowGroupsModal(false);
            setSelectedUser(null);
          }}
          onSave={handleGroupsUpdated}
        />
      )}
    </div>
  );
}

export default UserManagement;