import { collection, addDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import type { CalendarEvent } from '../types';

interface WebhookConfig {
  enabled: boolean;
  key: string;
  fieldMapping: {
    title: string;
    startTime: string;
    endTime: string;
    description?: string;
    location?: string;
  };
}

export async function configureWebhook(calendarId: string, config: Partial<WebhookConfig>) {
  try {
    const calendarRef = doc(db, 'calendars', calendarId);
    const calendarDoc = await getDoc(calendarRef);
    
    if (!calendarDoc.exists()) {
      throw new Error('Calendar not found');
    }

    // Generate new webhook key
    const webhookKey = generateWebhookKey();
    
    // Get the correct domain based on environment
    const baseUrl = window.location.hostname.includes('netlify.app') 
      ? `https://${window.location.hostname}`
      : 'http://webhook2.vip-server.nl';

    // Configure webhook with the new key
    await updateDoc(calendarRef, {
      webhook: {
        ...config,
        key: webhookKey,
        enabled: config.enabled ?? false,
        stats: {
          totalEventsReceived: 0,
          lastEventReceived: null
        },
        lastUpdated: new Date().toISOString()
      }
    });

    // Return both the full webhook URL and the secret key
    return {
      webhookUrl: `${baseUrl}/api/calendar/webhook/${calendarId}/${webhookKey}`,
      webhookKey,
      config: {
        url: `${baseUrl}/api/calendar/webhook/${calendarId}/${webhookKey}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        secretKey: webhookKey
      }
    };
  } catch (error) {
    console.error('Error configuring webhook:', error);
    throw new Error('Failed to configure webhook');
  }
}

export async function handleWebhookCall(calendarId: string, webhookKey: string, data: any) {
  try {
    // Get calendar and webhook config
    const calendarRef = doc(db, 'calendars', calendarId);
    const calendarDoc = await getDoc(calendarRef);
    
    if (!calendarDoc.exists()) {
      throw new Error('Calendar not found');
    }

    const webhookConfig = calendarDoc.data().webhook;
    if (!webhookConfig?.enabled || webhookConfig.key !== webhookKey) {
      throw new Error('Invalid webhook configuration or key');
    }

    // Map fields according to configuration
    const event: Partial<CalendarEvent> = {
      calendarId,
      title: data[webhookConfig.fieldMapping.title] || data.title,
      startTime: new Date(data[webhookConfig.fieldMapping.startTime] || data.start_time).toISOString(),
      endTime: new Date(data[webhookConfig.fieldMapping.endTime] || data.end_time).toISOString(),
      description: data[webhookConfig.fieldMapping.description || 'description'],
      location: data[webhookConfig.fieldMapping.location || 'location'],
      status: 'pending',
      source: 'webhook',
      metadata: {
        originalData: data,
        receivedAt: new Date().toISOString(),
        webhookKey
      }
    };

    // Validate required fields
    if (!event.title || !event.startTime || !event.endTime) {
      throw new Error('Missing required fields');
    }

    // Store event
    const eventRef = await addDoc(collection(db, 'calendar_events'), {
      ...event,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    });

    // Update webhook stats
    await updateDoc(calendarRef, {
      'webhook.stats': {
        lastEventReceived: new Date().toISOString(),
        totalEventsReceived: (webhookConfig.stats?.totalEventsReceived || 0) + 1,
        lastSuccessfulKey: webhookKey
      }
    });

    return { 
      success: true, 
      eventId: eventRef.id,
      message: 'Event successfully created'
    };

  } catch (error) {
    console.error('Error processing webhook:', error);
    throw error;
  }
}

function generateWebhookKey(): string {
  // Generate a secure random key
  const bytes = new Uint8Array(32);
  crypto.getRandomValues(bytes);
  const key = Array.from(bytes)
    .map(b => b.toString(16).padStart(2, '0'))
    .join('');
  
  // Format as a more readable string
  return `whk_${key.substring(0, 32)}`;
}

export function validateWebhookData(data: any, fieldMapping: any): boolean {
  const requiredFields = ['title', 'startTime', 'endTime'];
  
  for (const field of requiredFields) {
    const mappedField = fieldMapping[field];
    if (!mappedField || !data[mappedField]) {
      return false;
    }
  }

  // Validate date formats
  try {
    new Date(data[fieldMapping.startTime]).toISOString();
    new Date(data[fieldMapping.endTime]).toISOString();
  } catch {
    return false;
  }

  return true;
}