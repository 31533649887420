import React from 'react';
import { MessageSquare } from 'lucide-react';
import SMSTemplateManager from '../../sms/components/SMSTemplateManager';

function SMSSettings() {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center mb-6">
        <MessageSquare className="h-6 w-6 text-primary mr-2" />
        <h1 className="text-2xl font-bold">SMS Instellingen</h1>
      </div>

      <div className="bg-white rounded-lg shadow-md">
        <SMSTemplateManager />
      </div>
    </div>
  );
}

export default SMSSettings;