import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { MessageSquare, TrendingUp, AlertCircle, CreditCard } from 'lucide-react';

interface SMSStats {
  totalSent: number;
  totalDelivered: number;
  totalFailed: number;
  costTotal: number;
  credits: number;
  byDate: {
    [date: string]: {
      sent: number;
      delivered: number;
      failed: number;
      cost: number;
    };
  };
}

function SMSStats() {
  const [stats, setStats] = useState<SMSStats>({
    totalSent: 0,
    totalDelivered: 0,
    totalFailed: 0,
    costTotal: 0,
    credits: 0,
    byDate: {}
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [dateRange, setDateRange] = useState('week');

  useEffect(() => {
    loadStats();
  }, [dateRange]);

  async function loadStats() {
    try {
      const startDate = new Date();
      if (dateRange === 'week') startDate.setDate(startDate.getDate() - 7);
      if (dateRange === 'month') startDate.setMonth(startDate.getMonth() - 1);
      if (dateRange === 'year') startDate.setFullYear(startDate.getFullYear() - 1);

      const messagesQuery = query(
        collection(db, 'sms_messages'),
        where('sentAt', '>=', startDate.toISOString())
      );

      const querySnapshot = await getDocs(messagesQuery);
      const messages = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Process messages into stats
      const newStats: SMSStats = {
        totalSent: 0,
        totalDelivered: 0,
        totalFailed: 0,
        costTotal: 0,
        credits: stats.credits,
        byDate: {}
      };

      messages.forEach(msg => {
        const date = new Date(msg.sentAt).toLocaleDateString();
        if (!newStats.byDate[date]) {
          newStats.byDate[date] = {
            sent: 0,
            delivered: 0,
            failed: 0,
            cost: 0
          };
        }

        newStats.totalSent++;
        newStats.byDate[date].sent++;

        if (msg.status === 'delivered') {
          newStats.totalDelivered++;
          newStats.byDate[date].delivered++;
        } else if (msg.status === 'failed') {
          newStats.totalFailed++;
          newStats.byDate[date].failed++;
        }

        if (msg.cost) {
          newStats.costTotal += msg.cost;
          newStats.byDate[date].cost += msg.cost;
        }
      });

      setStats(newStats);
    } catch (err) {
      setError('Fout bij het laden van statistieken');
    } finally {
      setLoading(false);
    }
  }

  const chartData = Object.entries(stats.byDate).map(([date, data]) => ({
    date,
    ...data
  }));

  if (loading) {
    return <div className="p-6">Statistieken laden...</div>;
  }

  return (
    <div className="p-6">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-6">
        <div className="bg-blue-50 p-4 rounded-lg">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-blue-600 text-sm font-medium">Totaal Verzonden</p>
              <p className="text-2xl font-bold text-blue-800">{stats.totalSent}</p>
            </div>
            <MessageSquare className="h-8 w-8 text-blue-500" />
          </div>
        </div>

        <div className="bg-green-50 p-4 rounded-lg">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-green-600 text-sm font-medium">Afgeleverd</p>
              <p className="text-2xl font-bold text-green-800">{stats.totalDelivered}</p>
            </div>
            <TrendingUp className="h-8 w-8 text-green-500" />
          </div>
        </div>

        <div className="bg-red-50 p-4 rounded-lg">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-red-600 text-sm font-medium">Mislukt</p>
              <p className="text-2xl font-bold text-red-800">{stats.totalFailed}</p>
            </div>
            <AlertCircle className="h-8 w-8 text-red-500" />
          </div>
        </div>

        <div className="bg-purple-50 p-4 rounded-lg">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-purple-600 text-sm font-medium">SMS Tegoed</p>
              <p className="text-2xl font-bold text-purple-800">{stats.credits}</p>
            </div>
            <CreditCard className="h-8 w-8 text-purple-500" />
          </div>
        </div>
      </div>

      <div className="mb-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">Berichten per Periode</h3>
          <select
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value)}
            className="rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
          >
            <option value="week">Afgelopen Week</option>
            <option value="month">Afgelopen Maand</option>
            <option value="year">Afgelopen Jaar</option>
          </select>
        </div>

        <div className="h-80">
          <BarChart width={800} height={300} data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="sent" name="Verzonden" fill="#3B82F6" />
            <Bar dataKey="delivered" name="Afgeleverd" fill="#10B981" />
            <Bar dataKey="failed" name="Mislukt" fill="#EF4444" />
          </BarChart>
        </div>
      </div>

      <div className="mt-6 p-4 bg-gray-50 rounded-lg">
        <h3 className="text-lg font-medium mb-2">Kosten Overzicht</h3>
        <p className="text-gray-600">
          Totale kosten: €{(stats.costTotal / 100).toFixed(2)}
        </p>
      </div>
    </div>
  );
}

export default SMSStats;