import React from 'react';

interface Props {
  credentials: {
    username: string;
    password: string;
  };
  onChange: (credentials: Props['credentials']) => void;
  disabled?: boolean;
}

function SIPCredentials({ credentials, onChange, disabled }: Props) {
  return (
    <div>
      <h2 className="text-lg font-semibold mb-4">SIP Inloggegevens</h2>
      
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            SIP Username
          </label>
          <input
            type="text"
            value={credentials.username}
            onChange={(e) => onChange({ ...credentials, username: e.target.value })}
            disabled={disabled}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            SIP Password
          </label>
          <input
            type="password"
            value={credentials.password}
            onChange={(e) => onChange({ ...credentials, password: e.target.value })}
            disabled={disabled}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
          />
        </div>
      </div>
    </div>
  );
}

export default SIPCredentials;