import React, { useState, useEffect } from 'react';
import { Cloud, Save } from 'lucide-react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { useRoleAccess, ROLES } from '../../../hooks/useRoleAccess';

function Microsoft365Settings() {
  const { hasAccess } = useRoleAccess([ROLES.ADMIN, ROLES.SUPERADMIN]);
  const [settings, setSettings] = useState({
    clientId: '60fdd6aa-3954-49ba-9b3f-6d03b26bf479',
    tenantId: '418e2a4a-28fc-41c3-bdec-56078f098d6f',
    redirectUrl: window.location.origin,
    enabled: true
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    loadSettings();
  }, []);

  async function loadSettings() {
    try {
      const configDoc = await getDoc(doc(db, 'system_config', 'microsoft365'));
      if (configDoc.exists()) {
        setSettings(configDoc.data());
      }
    } catch (err) {
      setError('Fout bij het laden van Microsoft 365 instellingen');
    } finally {
      setLoading(false);
    }
  }

  async function handleSave() {
    try {
      setSaving(true);
      setError('');
      setSuccess('');

      await updateDoc(doc(db, 'system_config', 'microsoft365'), {
        ...settings,
        updatedAt: new Date().toISOString()
      });

      setSuccess('Microsoft 365 instellingen succesvol opgeslagen');
    } catch (err) {
      setError('Fout bij het opslaan van Microsoft 365 instellingen');
    } finally {
      setSaving(false);
    }
  }

  if (!hasAccess) {
    return (
      <div className="text-center py-8 text-red-600">
        U heeft geen toegang tot deze pagina.
      </div>
    );
  }

  if (loading) {
    return (
      <div className="text-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary mx-auto"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center mb-6">
        <Cloud className="h-6 w-6 text-primary mr-2" />
        <h1 className="text-2xl font-bold">Microsoft 365 Instellingen</h1>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      {success && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
          {success}
        </div>
      )}

      <div className="bg-white rounded-lg shadow-md p-6 space-y-6">
        <div>
          <h2 className="text-lg font-semibold mb-4">Azure AD Configuratie</h2>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Application (client) ID
              </label>
              <input
                type="text"
                value={settings.clientId}
                onChange={(e) => setSettings({ ...settings, clientId: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Directory (tenant) ID
              </label>
              <input
                type="text"
                value={settings.tenantId}
                onChange={(e) => setSettings({ ...settings, tenantId: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Redirect URL
              </label>
              <input
                type="text"
                value={settings.redirectUrl}
                onChange={(e) => setSettings({ ...settings, redirectUrl: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
              />
              <p className="mt-1 text-sm text-gray-500">
                Dit moet overeenkomen met de redirect URL in Azure AD
              </p>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                checked={settings.enabled}
                onChange={(e) => setSettings({ ...settings, enabled: e.target.checked })}
                className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm text-gray-900">
                Microsoft 365 integratie inschakelen
              </label>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            onClick={handleSave}
            disabled={saving}
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            <Save className="h-4 w-4 mr-2" />
            {saving ? 'Bezig met opslaan...' : 'Instellingen opslaan'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Microsoft365Settings;