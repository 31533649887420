import React from 'react';
import { AlertTriangle, CheckCircle } from 'lucide-react';
import { ERROR_EXPLANATIONS } from '../constants/errorMessages';

interface Props {
  testError: keyof typeof ERROR_EXPLANATIONS | null;
  testSuccess: boolean;
}

function VoIPTestResult({ testError, testSuccess }: Props) {
  if (!testError && !testSuccess) return null;

  return (
    <div className={`p-4 rounded-lg ${
      testSuccess ? 'bg-green-50 border border-green-200' : 'bg-red-50 border border-red-200'
    }`}>
      <div className="flex items-start">
        {testSuccess ? (
          <CheckCircle className="h-5 w-5 text-green-500 mt-0.5 mr-2" />
        ) : (
          <AlertTriangle className="h-5 w-5 text-red-500 mt-0.5 mr-2" />
        )}
        <div>
          {testSuccess ? (
            <h3 className="font-medium text-green-800">Verbinding succesvol</h3>
          ) : testError && ERROR_EXPLANATIONS[testError] ? (
            <>
              <h3 className="font-medium text-red-800">{ERROR_EXPLANATIONS[testError].title}</h3>
              <p className="mt-1 text-sm text-red-700">{ERROR_EXPLANATIONS[testError].description}</p>
              <p className="mt-2 text-sm font-medium text-red-700">Oplossing:</p>
              <p className="text-sm text-red-700">{ERROR_EXPLANATIONS[testError].solution}</p>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default VoIPTestResult;