import React, { useState, useEffect } from 'react';
import { Phone, Calendar, MessageSquare, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { SMSTemplate } from '../../modules/sms/services/templateService';
import MobileQuickSMS from './MobileQuickSMS';
import MobileCalendar from './MobileCalendar';

function MobileDashboard() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [templates, setTemplates] = useState<SMSTemplate[]>([]);
  const [events, setEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showSMSModal, setShowSMSModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<SMSTemplate | null>(null);

  useEffect(() => {
    loadTemplates();
    loadTodayEvents();
  }, [currentUser]);

  async function loadTemplates() {
    try {
      const templatesQuery = query(
        collection(db, 'sms_templates'),
        where('isDefault', '==', true),
        limit(5)
      );
      const snapshot = await getDocs(templatesQuery);
      setTemplates(snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as SMSTemplate[]);
    } catch (err) {
      setError('Fout bij het laden van templates');
    }
  }

  async function loadTodayEvents() {
    try {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      const eventsQuery = query(
        collection(db, 'calendar_events'),
        where('startTime', '>=', today.toISOString()),
        where('startTime', '<', tomorrow.toISOString()),
        orderBy('startTime', 'asc')
      );

      const snapshot = await getDocs(eventsQuery);
      setEvents(snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));
    } catch (err) {
      setError('Fout bij het laden van agenda');
    } finally {
      setLoading(false);
    }
  }

  const handleTemplateSelect = (template: SMSTemplate) => {
    setSelectedTemplate(template);
    setShowSMSModal(true);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="px-4 py-6 space-y-6">
      {/* Quick Actions */}
      <div className="grid grid-cols-3 gap-4">
        <a
          href="tel:"
          className="flex flex-col items-center justify-center p-4 bg-primary text-white rounded-lg shadow-md"
        >
          <Phone className="h-6 w-6 mb-2" />
          <span className="text-sm">Bellen</span>
        </a>

        <button
          onClick={() => setShowSMSModal(true)}
          className="flex flex-col items-center justify-center p-4 bg-white text-primary rounded-lg shadow-md border border-primary"
        >
          <MessageSquare className="h-6 w-6 mb-2" />
          <span className="text-sm">SMS</span>
        </button>

        <button
          onClick={() => navigate('/calendar')}
          className="flex flex-col items-center justify-center p-4 bg-white text-primary rounded-lg shadow-md border border-primary"
        >
          <Calendar className="h-6 w-6 mb-2" />
          <span className="text-sm">Agenda</span>
        </button>
      </div>

      {/* Today's Calendar */}
      <div className="bg-white rounded-lg shadow-md p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Vandaag</h2>
          <button
            onClick={() => navigate('/calendar')}
            className="text-primary flex items-center text-sm"
          >
            Bekijk meer
            <ChevronRight className="h-4 w-4 ml-1" />
          </button>
        </div>

        <MobileCalendar events={events} />
      </div>

      {/* Quick SMS Templates */}
      <div className="bg-white rounded-lg shadow-md p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Snelle SMS</h2>
          <button
            onClick={() => navigate('/sms')}
            className="text-primary flex items-center text-sm"
          >
            Alle templates
            <ChevronRight className="h-4 w-4 ml-1" />
          </button>
        </div>

        <div className="space-y-3">
          {templates.map(template => (
            <button
              key={template.id}
              onClick={() => handleTemplateSelect(template)}
              className="w-full text-left p-3 rounded-lg border border-gray-200 hover:border-primary focus:outline-none focus:ring-2 focus:ring-primary"
            >
              <h3 className="font-medium">{template.name}</h3>
              <p className="text-sm text-gray-600 line-clamp-2">{template.content}</p>
            </button>
          ))}
        </div>
      </div>

      {/* Quick SMS Modal */}
      {showSMSModal && (
        <MobileQuickSMS
          template={selectedTemplate}
          onClose={() => {
            setShowSMSModal(false);
            setSelectedTemplate(null);
          }}
        />
      )}
    </div>
  );
}

export default MobileDashboard;