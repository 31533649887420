export const ERROR_EXPLANATIONS = {
  'REGISTER_FAILED': {
    title: 'Registratie mislukt',
    description: 'De VoIP server accepteert de inloggegevens niet. Controleer gebruikersnaam en wachtwoord.',
    solution: 'Verifieer de SIP inloggegevens bij CheapConnect.'
  },
  'CONNECTION_FAILED': {
    title: 'Verbinding mislukt',
    description: 'Kan geen verbinding maken met de VoIP server.',
    solution: 'Controleer of de server en poort correct zijn en bereikbaar zijn.'
  },
  'AUTH_FAILED': {
    title: 'Authenticatie mislukt',
    description: 'De inloggegevens zijn onjuist of het account is geblokkeerd.',
    solution: 'Controleer de inloggegevens of neem contact op met CheapConnect.'
  },
  'PORT_BLOCKED': {
    title: 'Poort geblokkeerd',
    description: 'De geselecteerde poort lijkt geblokkeerd te zijn door een firewall.',
    solution: 'Probeer een andere poort of controleer firewall instellingen.'
  },
  'SIP_ALG': {
    title: 'SIP ALG gedetecteerd',
    description: 'SIP ALG is ingeschakeld op de router wat problemen kan veroorzaken.',
    solution: 'Schakel SIP ALG uit in de router instellingen of gebruik poort 5080.'
  }
} as const;