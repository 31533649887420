import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';

// Firebase configuratie voor voip1-nederland project
const firebaseConfig = {
  apiKey: "AIzaSyDy2OVx3ukCSs4vAlxPwspmYj2cVLWaPlk",
  authDomain: "voip1-nederland.firebaseapp.com",
  projectId: "voip1-nederland",
  storageBucket: "voip1-nederland.appspot.com",
  messagingSenderId: "178577330950",
  appId: "1:178577330950:web:8183ab40c5b6bccc7d8487"
};

// Initialize Firebase met retry mechanisme
let app;
let auth;
let db;

export const initializeFirebase = async (retries = 3) => {
  for (let i = 0; i < retries; i++) {
    try {
      if (!app) {
        app = initializeApp(firebaseConfig);
        auth = getAuth(app);
        db = getFirestore(app);

        // Enable offline persistence
        try {
          await enableIndexedDbPersistence(db);
          console.log('Firebase initialized successfully');
          return true;
        } catch (err: any) {
          if (err.code === 'failed-precondition') {
            console.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
          } else if (err.code === 'unimplemented') {
            console.warn('The current browser doesn\'t support persistence.');
          }
        }
      }
      return true;
    } catch (error) {
      console.error(`Firebase initialization attempt ${i + 1} failed:`, error);
      if (i === retries - 1) throw error;
      // Wait before retrying (exponential backoff)
      await new Promise(resolve => setTimeout(resolve, Math.pow(2, i) * 1000));
    }
  }
  return false;
};

// Firebase Auth Helper Functions
export const loginWithEmail = async (email: string, password: string) => {
  if (!auth) throw new Error('Firebase not initialized');
  
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error: any) {
    console.error("Login error:", error);
    if (error.code === 'auth/invalid-credential') {
      throw new Error('Ongeldige inloggegevens');
    }
    throw error;
  }
};

export const logout = async () => {
  if (!auth) throw new Error('Firebase not initialized');

  try {
    await signOut(auth);
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// Initialize Firebase immediately
initializeFirebase().catch(console.error);

export { app, auth, db };