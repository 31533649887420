import React, { useState, useEffect } from 'react';
import { Plus, Edit, Trash2, Star } from 'lucide-react';
import { getSenders, createSender, updateSender, deleteSender } from '../services/senderService';
import type { SMSSender } from '../services/senderService';
import { useAuth } from '../../../contexts/AuthContext';

function SMSSenderManager() {
  const { currentUser } = useAuth();
  const [senders, setSenders] = useState<SMSSender[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    name: '',
    value: '',
    isDefault: false
  });

  useEffect(() => {
    loadSenders();
  }, []);

  async function loadSenders() {
    try {
      const loadedSenders = await getSenders();
      setSenders(loadedSenders);
    } catch (err) {
      setError('Fout bij het laden van afzenders');
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    
    try {
      setError('');
      setSuccess('');

      if (editingId) {
        await updateSender(editingId, formData);
        setSuccess('Afzender succesvol bijgewerkt');
      } else {
        await createSender({
          ...formData,
          createdBy: currentUser!.uid
        });
        setSuccess('Afzender succesvol toegevoegd');
      }

      setFormData({ name: '', value: '', isDefault: false });
      setShowAddForm(false);
      setEditingId(null);
      loadSenders();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Fout bij het opslaan van afzender');
    }
  }

  async function handleDelete(id: string) {
    try {
      const sender = senders.find(s => s.id === id);
      if (sender?.isDefault) {
        setError('De standaard afzender kan niet worden verwijderd');
        return;
      }

      await deleteSender(id);
      setSuccess('Afzender succesvol verwijderd');
      loadSenders();
    } catch (err) {
      setError('Fout bij het verwijderen van afzender');
    }
  }

  async function handleSetDefault(sender: SMSSender) {
    try {
      // Remove default status from current default sender
      const currentDefault = senders.find(s => s.isDefault);
      if (currentDefault) {
        await updateSender(currentDefault.id, { isDefault: false });
      }

      // Set new default sender
      await updateSender(sender.id, { isDefault: true });
      setSuccess('Standaard afzender succesvol gewijzigd');
      loadSenders();
    } catch (err) {
      setError('Fout bij het instellen van standaard afzender');
    }
  }

  if (loading) {
    return <div>Laden...</div>;
  }

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          {error}
        </div>
      )}

      {success && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded">
          {success}
        </div>
      )}

      {!showAddForm && (
        <button
          onClick={() => setShowAddForm(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          <Plus className="h-5 w-5 mr-2" />
          Nieuwe Afzender
        </button>
      )}

      {showAddForm && (
        <form onSubmit={handleSubmit} className="bg-gray-50 p-4 rounded-lg">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Naam
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Afzender ID
              </label>
              <input
                type="text"
                value={formData.value}
                onChange={(e) => setFormData({ ...formData, value: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                required
                maxLength={11}
                pattern="[a-zA-Z0-9]+"
              />
              <p className="mt-1 text-sm text-gray-500">
                Alleen letters en cijfers, maximaal 11 tekens
              </p>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                id="isDefault"
                checked={formData.isDefault}
                onChange={(e) => setFormData({ ...formData, isDefault: e.target.checked })}
                className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
              />
              <label htmlFor="isDefault" className="ml-2 block text-sm text-gray-900">
                Instellen als standaard afzender
              </label>
            </div>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => {
                  setShowAddForm(false);
                  setEditingId(null);
                  setFormData({ name: '', value: '', isDefault: false });
                }}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Annuleren
              </button>
              <button
                type="submit"
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                {editingId ? 'Bijwerken' : 'Opslaan'}
              </button>
            </div>
          </div>
        </form>
      )}

      <div className="space-y-4">
        {senders.map(sender => (
          <div
            key={sender.id}
            className="bg-white p-4 rounded-lg border border-gray-200"
          >
            <div className="flex justify-between items-center">
              <div>
                <h3 className="font-medium">{sender.name}</h3>
                <p className="text-gray-600">{sender.value}</p>
                {sender.isDefault && (
                  <span className="inline-flex items-center px-2 py-1 text-xs bg-yellow-100 text-yellow-800 rounded">
                    <Star className="h-3 w-3 mr-1" />
                    Standaard
                  </span>
                )}
              </div>
              <div className="flex space-x-2">
                {!sender.isDefault && (
                  <button
                    onClick={() => handleSetDefault(sender)}
                    className="text-yellow-600 hover:text-yellow-800"
                    title="Instellen als standaard"
                  >
                    <Star className="h-5 w-5" />
                  </button>
                )}
                <button
                  onClick={() => {
                    setFormData({
                      name: sender.name,
                      value: sender.value,
                      isDefault: sender.isDefault
                    });
                    setEditingId(sender.id);
                    setShowAddForm(true);
                  }}
                  className="text-blue-600 hover:text-blue-800"
                >
                  <Edit className="h-5 w-5" />
                </button>
                {!sender.isDefault && (
                  <button
                    onClick={() => handleDelete(sender.id)}
                    className="text-red-600 hover:text-red-800"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}

        {senders.length === 0 && (
          <p className="text-gray-500 text-center py-4">
            Nog geen afzenders beschikbaar
          </p>
        )}
      </div>
    </div>
  );
}

export default SMSSenderManager;