import React, { useState, useEffect } from 'react';
import { format, addDays, subDays, startOfWeek, endOfWeek, eachDayOfInterval, isSameDay } from 'date-fns';
import { nl } from 'date-fns/locale';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { getEvents } from '../services/calendarService';
import type { Calendar, CalendarEvent } from '../types';
import MonthView from './MonthView';
import EventDetailsModal from './EventDetailsModal';

interface Props {
  calendars: Calendar[];
}

function CalendarView({ calendars }: Props) {
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [view, setView] = useState<'day' | 'week'>('day');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showMonthView, setShowMonthView] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);

  useEffect(() => {
    if (calendars.length > 0) {
      loadEvents();
    } else {
      setEvents([]);
      setLoading(false);
    }
  }, [calendars, selectedDate, view]);

  async function loadEvents() {
    try {
      setLoading(true);
      console.log('Loading events for calendars:', calendars);
      const calendarIds = calendars.map(cal => cal.id);
      const loadedEvents = await getEvents(calendarIds);
      console.log('Loaded events:', loadedEvents);
      setEvents(loadedEvents);
    } catch (err) {
      console.error('Error loading events:', err);
      setError('Fout bij het laden van afspraken');
    } finally {
      setLoading(false);
    }
  }

  const getVisibleEvents = () => {
    if (view === 'week') {
      const weekStart = startOfWeek(selectedDate, { locale: nl, weekStartsOn: 1 });
      const weekEnd = endOfWeek(selectedDate, { locale: nl, weekStartsOn: 1 });
      const days = eachDayOfInterval({ start: weekStart, end: weekEnd });

      return days.map(day => ({
        date: day,
        events: events.filter(event => isSameDay(new Date(event.startTime), day))
      }));
    } else {
      return [{
        date: selectedDate,
        events: events.filter(event => isSameDay(new Date(event.startTime), selectedDate))
      }];
    }
  };

  const navigateDate = (direction: 'prev' | 'next') => {
    const days = view === 'week' ? 7 : 1;
    setSelectedDate(current => 
      direction === 'next' 
        ? addDays(current, days)
        : subDays(current, days)
    );
  };

  if (loading) {
    return <div className="text-center py-8">Laden...</div>;
  }

  if (showMonthView) {
    return (
      <MonthView 
        events={events}
        calendars={calendars}
        onClose={() => setShowMonthView(false)}
        onDateSelect={(date) => {
          setSelectedDate(date);
          setShowMonthView(false);
          setView('day');
        }}
      />
    );
  }

  const visibleDays = getVisibleEvents();

  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="p-4 border-b">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <button
                onClick={() => navigateDate('prev')}
                className="p-2 rounded hover:bg-gray-100"
              >
                <ChevronLeft className="h-5 w-5" />
              </button>
              
              <span className="text-sm font-medium min-w-[150px] text-center">
                {view === 'week' ? (
                  `Week ${format(selectedDate, 'w', { locale: nl })}`
                ) : (
                  format(selectedDate, 'EEEE d MMMM', { locale: nl })
                )}
              </span>
              
              <button
                onClick={() => navigateDate('next')}
                className="p-2 rounded hover:bg-gray-100"
              >
                <ChevronRight className="h-5 w-5" />
              </button>
            </div>

            <div className="flex space-x-2">
              <button
                onClick={() => setView('day')}
                className={`px-4 py-2 rounded-lg ${
                  view === 'day' ? 'bg-primary text-white' : 'bg-gray-100'
                }`}
              >
                Dag
              </button>
              <button
                onClick={() => setView('week')}
                className={`px-4 py-2 rounded-lg ${
                  view === 'week' ? 'bg-primary text-white' : 'bg-gray-100'
                }`}
              >
                Week
              </button>
              <button
                onClick={() => setShowMonthView(true)}
                className="px-4 py-2 rounded-lg bg-gray-100"
              >
                Maand
              </button>
            </div>
          </div>
        </div>
      </div>

      {error && (
        <div className="p-4 bg-red-100 border-b border-red-200 text-red-700">
          {error}
        </div>
      )}

      <div className="divide-y">
        {visibleDays.map(({ date, events: dayEvents }) => (
          <div key={date.toISOString()} className="p-4">
            <h3 className="font-medium mb-4">
              {format(date, view === 'week' ? 'EEEE d MMMM' : 'HH:mm', { locale: nl })}
            </h3>

            <div className="space-y-3">
              {dayEvents.length === 0 ? (
                <p className="text-gray-500 text-center py-4">
                  Geen afspraken
                </p>
              ) : (
                dayEvents.map((event) => {
                  const calendar = calendars.find(cal => cal.id === event.calendarId);
                  return (
                    <button
                      key={`${event.id || event.calendarId}-${event.startTime}-${event.title}`}
                      onClick={() => setSelectedEvent(event)}
                      className={`w-full text-left p-4 rounded-lg border-l-4 bg-gray-50 hover:bg-gray-100 transition-colors ${
                        event.status === 'completed' ? 'bg-green-50 hover:bg-green-100' : ''
                      }`}
                      style={{ borderLeftColor: calendar?.color || '#e5e7eb' }}
                    >
                      <div className="flex justify-between items-start">
                        <div>
                          <h4 className="font-medium">{event.title}</h4>
                          <p className="text-sm text-gray-600">
                            {format(new Date(event.startTime), 'HH:mm')} - 
                            {format(new Date(event.endTime), 'HH:mm')}
                          </p>
                          {event.location && (
                            <p className="text-sm text-gray-600 mt-1">
                              📍 {event.location}
                            </p>
                          )}
                          {event.status === 'completed' && (
                            <p className="text-sm text-green-600 mt-1">
                              ✓ Afgerond
                            </p>
                          )}
                        </div>
                      </div>
                    </button>
                  );
                })
              )}
            </div>
          </div>
        ))}
      </div>

      {selectedEvent && (
        <EventDetailsModal
          event={selectedEvent}
          calendar={calendars.find(cal => cal.id === selectedEvent.calendarId)}
          onClose={() => setSelectedEvent(null)}
        />
      )}
    </div>
  );
}

export default CalendarView;