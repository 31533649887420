import { EventEmitter } from 'events';
import JsSIP from 'jssip';

interface VoIPConfig {
  allowIncoming: boolean;
  sipCredentials: {
    username: string;
    password: string;
  };
  server: {
    primary: string;
    alternative: string;
    port: number;
  };
}

export class VoIPService extends EventEmitter {
  private ua: JsSIP.UA | null = null;
  private session: JsSIP.RTCSession | null = null;
  private config: VoIPConfig | null = null;
  private remoteAudio: HTMLAudioElement;
  private isInitialized: boolean = false;

  constructor() {
    super();
    JsSIP.debug.enable('JsSIP:*');
    this.remoteAudio = new Audio();
    this.remoteAudio.autoplay = true;
  }

  initialize(config: VoIPConfig) {
    if (this.isInitialized) {
      this.disconnect();
    }

    this.config = config;
    const socket = new JsSIP.WebSocketInterface(
      `wss://${config.server.primary}:${config.server.port}`
    );

    const configuration = {
      sockets: [socket],
      uri: `sip:${config.sipCredentials.username}@${config.server.primary}`,
      password: config.sipCredentials.password,
      register: true,
      register_expires: 300,
      session_timers: false,
      user_agent: 'VoIP Group Manager',
      no_answer_timeout: 60,
      use_preloaded_route: false
    };

    try {
      this.ua = new JsSIP.UA(configuration);
      this.setupUAListeners();
      this.ua.start();
      this.isInitialized = true;
    } catch (error) {
      console.error('Error initializing VoIP service:', error);
      throw error;
    }
  }

  private setupUAListeners() {
    if (!this.ua) return;

    this.ua.on('connected', () => {
      this.emit('connected');
    });

    this.ua.on('disconnected', () => {
      this.emit('disconnected');
    });

    this.ua.on('registered', () => {
      this.emit('registered');
    });

    this.ua.on('unregistered', () => {
      this.emit('unregistered');
    });

    this.ua.on('registrationFailed', (data: any) => {
      this.emit('registrationFailed', data);
    });

    this.ua.on('newRTCSession', ({ session, originator }: { session: JsSIP.RTCSession, originator: string }) => {
      if (originator === 'remote' && !this.config?.allowIncoming) {
        session.terminate({
          status_code: 403,
          reason_phrase: 'Inkomende gesprekken niet toegestaan'
        });
        return;
      }

      if (this.session) {
        this.session.terminate();
      }

      this.session = session;
      this.setupSessionListeners();

      if (originator === 'remote' && this.config?.allowIncoming) {
        const caller = session.remote_identity.uri.user;
        this.emit('incomingCall', {
          caller,
          session
        });
      }
    });
  }

  private setupSessionListeners() {
    if (!this.session) return;

    this.session.on('accepted', () => {
      this.emit('callAccepted');
      const stream = new MediaStream();
      this.session?.connection.getReceivers().forEach(receiver => {
        if (receiver.track) {
          stream.addTrack(receiver.track);
        }
      });
      this.remoteAudio.srcObject = stream;
    });

    this.session.on('ended', () => {
      this.emit('callEnded');
      this.remoteAudio.srcObject = null;
    });

    this.session.on('failed', (data: any) => {
      this.emit('callFailed', data);
      this.remoteAudio.srcObject = null;
    });
  }

  async makeCall(extension: string) {
    if (!this.ua || !this.config) throw new Error('VoIP service not initialized');

    const options = {
      mediaConstraints: { audio: true, video: false },
      pcConfig: {
        iceServers: [
          { urls: ['stun:stun.l.google.com:19302'] }
        ]
      }
    };

    this.ua.call(`sip:${extension}@${this.config.server.primary}`, options);
  }

  answerCall() {
    if (!this.session) throw new Error('No active call');
    this.session.answer({
      mediaConstraints: { audio: true, video: false }
    });
  }

  rejectCall() {
    if (!this.session) throw new Error('No active call');
    this.session.terminate();
  }

  endCall() {
    if (!this.session) throw new Error('No active call');
    this.session.terminate();
  }

  mute() {
    if (!this.session) throw new Error('No active call');
    this.session.mute();
  }

  unmute() {
    if (!this.session) throw new Error('No active call');
    this.session.unmute();
  }

  sendDTMF(tone: string) {
    if (!this.session) throw new Error('No active call');
    this.session.sendDTMF(tone);
  }

  disconnect() {
    if (this.session) {
      this.session.terminate();
      this.session = null;
    }
    if (this.ua) {
      this.ua.stop();
      this.ua = null;
    }
    if (this.remoteAudio) {
      this.remoteAudio.srcObject = null;
    }
    this.isInitialized = false;
    this.config = null;
  }

  isConnected() {
    return this.ua?.isConnected() || false;
  }

  isRegistered() {
    return this.ua?.isRegistered() || false;
  }
}