import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useAuth } from '../../../contexts/AuthContext';
import { addCalendar } from '../services/calendarService';
import type { CalendarType, CalendarProvider } from '../types';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
}

const PROVIDER_INFO = {
  insezo: {
    name: 'Insezo',
    description: 'Voor Insezo werkbonnen en planning',
    urlFormat: 'https://rest.insezo.com/app/calendar/ics?api_key=JOUW_API_SLEUTEL',
    helpText: 'Voer je Insezo API sleutel in bij de URL'
  },
  google: {
    name: 'Google Agenda',
    description: 'Voor Google Calendar en Tunstall integratie',
    urlFormat: 'https://calendar.google.com/calendar/ical/JOUW_AGENDA_ID/basic.ics',
    helpText: 'Kopieer de privé iCal URL van je Google Agenda'
  },
  outlook: {
    name: 'Outlook',
    description: 'Voor Microsoft Outlook/Office 365',
    urlFormat: 'https://outlook.office365.com/owa/calendar/JOUW_ID/calendar.ics',
    helpText: 'Gebruik de iCal URL van je Outlook agenda'
  },
  ical: {
    name: 'iCal/ICS',
    description: 'Voor algemene iCal/ICS agenda\'s',
    urlFormat: 'https://voorbeeld.nl/agenda.ics',
    helpText: 'Voer de volledige URL van je iCal/ICS feed in'
  },
  zapier: {
    name: 'Zapier',
    description: 'Ontvang afspraken via Zapier webhooks',
    urlFormat: '',
    helpText: 'Na het aanmaken kun je de webhook URL configureren in de instellingen'
  }
};

function AddCalendarModal({ onClose, onSuccess }: Props) {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    type: 'personal' as CalendarType,
    color: '#2563eb',
    provider: 'ical' as CalendarProvider,
    url: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!currentUser) {
      setError('Je moet ingelogd zijn om een agenda toe te voegen');
      return;
    }

    try {
      setLoading(true);
      setError('');

      await addCalendar({
        ...formData,
        ownerId: currentUser.uid,
        settings: {},
        sharing: []
      });

      onSuccess();
    } catch (err) {
      console.error('Error adding calendar:', err);
      setError('Fout bij het toevoegen van agenda');
    } finally {
      setLoading(false);
    }
  };

  const selectedProvider = PROVIDER_INFO[formData.provider];

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Agenda Toevoegen</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="h-5 w-5" />
          </button>
        </div>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Naam
            </label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Provider
            </label>
            <select
              value={formData.provider}
              onChange={(e) => setFormData({ 
                ...formData, 
                provider: e.target.value as CalendarProvider,
                url: '' // Reset URL when changing provider
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
            >
              {Object.entries(PROVIDER_INFO).map(([key, info]) => (
                <option key={key} value={key}>
                  {info.name}
                </option>
              ))}
            </select>
            <p className="mt-1 text-sm text-gray-500">
              {selectedProvider.description}
            </p>
          </div>

          {formData.provider !== 'zapier' && (
            <div>
              <label className="block text-sm font-medium text-gray-700">
                URL
              </label>
              <input
                type="url"
                value={formData.url}
                onChange={(e) => setFormData({ ...formData, url: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                placeholder={selectedProvider.urlFormat}
                required={formData.provider !== 'zapier'}
              />
              <p className="mt-1 text-sm text-gray-500">
                {selectedProvider.helpText}
              </p>
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Kleur
            </label>
            <input
              type="color"
              value={formData.color}
              onChange={(e) => setFormData({ ...formData, color: e.target.value })}
              className="mt-1 block w-full h-10 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
            />
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Annuleren
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              {loading ? 'Bezig...' : 'Toevoegen'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddCalendarModal;