export function formatPhoneNumber(number: string): string {
  // Remove any non-digit characters
  const cleaned = number.replace(/\D/g, '');
  
  // Convert to international format if needed
  let formatted = cleaned;
  if (cleaned.startsWith('316')) {
    formatted = '+' + cleaned;
  } else if (cleaned.startsWith('06')) {
    formatted = '+31' + cleaned.substring(1);
  } else if (cleaned.startsWith('00316')) {
    formatted = '+' + cleaned.substring(2);
  }
  
  return formatted;
}

export function extractPhoneNumber(text: string): string | null {
  // Match only Dutch mobile numbers in various formats:
  // - 06 numbers (Dutch mobile)
  // - 316 numbers (International format without +)
  // - 00316 numbers (International format with 00)
  const phoneRegex = /(?:(?:00316|316)[1-9][0-9]{7})|(?:06[1-9][0-9]{7})/g;
  const matches = text.match(phoneRegex);
  
  if (matches && matches.length > 0) {
    // Return the first valid mobile number found
    return matches[0];
  }
  
  return null;
}