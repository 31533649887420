import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { useUserData } from '../../../hooks/useUserData';
import { Settings, Users, Phone } from 'lucide-react';

function AdminDashboard() {
  const { currentUser } = useAuth();
  const { userData, loading } = useUserData();

  if (loading) {
    return <div className="text-center py-8">Laden...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Beheerdersdashboard</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <Link
          to="/admin/groups"
          className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
        >
          <div className="flex items-center space-x-4">
            <Users className="h-8 w-8 text-primary" />
            <div>
              <h2 className="text-lg font-semibold">Groepsbeheer</h2>
              <p className="text-gray-600">Beheer groepen en gebruikers</p>
            </div>
          </div>
        </Link>

        <Link
          to="/admin/voip"
          className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
        >
          <div className="flex items-center space-x-4">
            <Phone className="h-8 w-8 text-primary" />
            <div>
              <h2 className="text-lg font-semibold">VoIP Instellingen</h2>
              <p className="text-gray-600">Configureer VoIP en SIP-instellingen</p>
            </div>
          </div>
        </Link>

        <Link
          to="/admin/settings"
          className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
        >
          <div className="flex items-center space-x-4">
            <Settings className="h-8 w-8 text-primary" />
            <div>
              <h2 className="text-lg font-semibold">Algemene Instellingen</h2>
              <p className="text-gray-600">Beheer systeeminstellingen</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default AdminDashboard;