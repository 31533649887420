import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { useVoIP } from '../hooks/useVoIP';
import { useAudioDevices } from '../hooks/useAudioDevices';
import VoIPStatusMonitor from './VoIPStatusMonitor';
import ServerHealthCheck from './ServerHealthCheck';
import AutoServerSwitch from './AutoServerSwitch';
import CallQualityMonitor from './CallQualityMonitor';
import VoIPDialer from './VoIPDialer';
import AudioDeviceSelector from './AudioDeviceSelector';

function VoIPClient() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [sipConfig, setSipConfig] = useState<any>(null);
  const [isMuted, setIsMuted] = useState(false);
  const [serverType, setServerType] = useState<'primary' | 'alternative'>('primary');
  const [showSettings, setShowSettings] = useState(false);

  const {
    status,
    callStatus,
    error: voipError,
    makeCall,
    endCall,
    mute,
    unmute,
    isConnected,
    isRegistered
  } = useVoIP(sipConfig);

  const { devices, setAudioDevice, setVolume } = useAudioDevices();

  useEffect(() => {
    loadSipConfig();
  }, [currentUser]);

  async function loadSipConfig() {
    try {
      const configDoc = await getDoc(doc(db, 'system_config', 'voip_settings'));
      if (configDoc.exists()) {
        setSipConfig(configDoc.data());
      }
    } catch (err) {
      setError('Fout bij het laden van VoIP configuratie');
    } finally {
      setLoading(false);
    }
  }

  const handleServerSwitch = (newServer: 'primary' | 'alternative') => {
    setServerType(newServer);
    if (sipConfig) {
      const updatedConfig = {
        ...sipConfig,
        server: {
          ...sipConfig.server,
          useAlternative: newServer === 'alternative'
        }
      };
      setSipConfig(updatedConfig);
    }
  };

  const handleCall = (number: string) => {
    if (!isConnected()) {
      setError('Niet verbonden met de VoIP server');
      return;
    }
    makeCall(number);
  };

  const handleMute = () => {
    if (isMuted) {
      unmute();
    } else {
      mute();
    }
    setIsMuted(!isMuted);
  };

  if (loading) {
    return <div className="text-center py-8">Laden...</div>;
  }

  return (
    <div className="max-w-md mx-auto space-y-6">
      {/* Status Monitor */}
      <VoIPStatusMonitor 
        status={status}
        serverType={serverType}
        error={voipError || error}
      />

      {/* Server Health & Auto Switch */}
      {sipConfig && (
        <>
          <ServerHealthCheck
            serverUrl={sipConfig.server[serverType === 'primary' ? 'primary' : 'alternative']}
            onStatusChange={(isHealthy) => {
              if (!isHealthy && status === 'connected') {
                handleServerSwitch(serverType === 'primary' ? 'alternative' : 'primary');
              }
            }}
          />
          <AutoServerSwitch
            primaryServer={sipConfig.server.primary}
            alternativeServer={sipConfig.server.alternative}
            onSwitch={handleServerSwitch}
          />
        </>
      )}

      {/* Dialer */}
      <VoIPDialer
        onCall={handleCall}
        onHangup={endCall}
        onMute={handleMute}
        disabled={callStatus === 'connected'}
        isMuted={isMuted}
      />

      {/* Call Quality Monitor */}
      {callStatus === 'connected' && (
        <CallQualityMonitor 
          onQualityChange={(quality) => {
            console.log('Call quality:', quality);
          }} 
        />
      )}

      {/* Audio Settings */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <button
          onClick={() => setShowSettings(!showSettings)}
          className="w-full text-left font-medium text-gray-700 mb-2"
        >
          Audio Instellingen {showSettings ? '▼' : '▶'}
        </button>

        {showSettings && (
          <div className="space-y-4">
            <AudioDeviceSelector onDeviceChange={setAudioDevice} />
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Volume
              </label>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={devices.volume}
                onChange={(e) => setVolume(parseFloat(e.target.value))}
                className="w-full"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default VoIPClient;