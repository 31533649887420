import { collection, query, where, getDocs, addDoc, deleteDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import type { Calendar, CalendarEvent } from '../types';

export async function getCalendars(userId: string): Promise<Calendar[]> {
  try {
    const calendarsQuery = query(
      collection(db, 'calendars'),
      where('ownerId', '==', userId)
    );
    
    const snapshot = await getDocs(calendarsQuery);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Calendar[];
  } catch (error) {
    console.error('Error fetching calendars:', error);
    return [];
  }
}

export async function addCalendar(calendar: Omit<Calendar, 'id'>): Promise<string> {
  try {
    const docRef = await addDoc(collection(db, 'calendars'), {
      ...calendar,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      syncStatus: 'pending',
      lastSync: null,
      lastError: null
    });

    return docRef.id;
  } catch (error) {
    console.error('Error adding calendar:', error);
    throw new Error('Fout bij het toevoegen van agenda');
  }
}

export async function deleteCalendar(calendarId: string): Promise<void> {
  try {
    // Delete calendar document
    await deleteDoc(doc(db, 'calendars', calendarId));

    // Delete all events for this calendar
    const eventsQuery = query(
      collection(db, 'calendar_events'),
      where('calendarId', '==', calendarId)
    );
    const eventsSnapshot = await getDocs(eventsQuery);
    const deletePromises = eventsSnapshot.docs.map(doc => deleteDoc(doc.ref));
    await Promise.all(deletePromises);
  } catch (error) {
    console.error('Error deleting calendar:', error);
    throw new Error('Fout bij het verwijderen van agenda');
  }
}

export async function getEvents(calendarIds: string[]): Promise<CalendarEvent[]> {
  try {
    const allEvents: CalendarEvent[] = [];

    for (const calendarId of calendarIds) {
      const eventsQuery = query(
        collection(db, 'calendar_events'),
        where('calendarId', '==', calendarId)
      );
      
      const snapshot = await getDocs(eventsQuery);
      const events = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as CalendarEvent[];

      allEvents.push(...events);
    }

    return allEvents;
  } catch (error) {
    console.error('Error fetching events:', error);
    return [];
  }
}

export async function updateEventStatus(eventId: string, status: string, userId: string): Promise<CalendarEvent> {
  try {
    const eventRef = doc(db, 'calendar_events', eventId);
    await updateDoc(eventRef, {
      status,
      completedBy: userId,
      completedAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    });

    const eventDoc = await getDoc(eventRef);
    return { id: eventDoc.id, ...eventDoc.data() } as CalendarEvent;
  } catch (error) {
    console.error('Error updating event status:', error);
    throw new Error('Fout bij het bijwerken van afspraakstatus');
  }
}

export async function updateCalendar(calendarId: string, updates: Partial<Calendar>): Promise<void> {
  try {
    await updateDoc(doc(db, 'calendars', calendarId), {
      ...updates,
      updatedAt: new Date().toISOString()
    });
  } catch (error) {
    console.error('Error updating calendar:', error);
    throw new Error('Fout bij het bijwerken van agenda');
  }
}

export async function testCalendarConnection(calendarId: string): Promise<boolean> {
  try {
    const calendarDoc = await getDoc(doc(db, 'calendars', calendarId));
    if (!calendarDoc.exists()) {
      throw new Error('Agenda niet gevonden');
    }

    const calendar = calendarDoc.data() as Calendar;
    const response = await fetch(calendar.url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return true;
  } catch (error) {
    console.error('Calendar connection test failed:', error);
    return false;
  }
}