import { useState, useEffect } from 'react';
import { VoIPService } from '../services/VoIPService';

export function useVoIP(config: any) {
  const [voip] = useState(() => new VoIPService());
  const [status, setStatus] = useState<'disconnected' | 'connecting' | 'connected'>('disconnected');
  const [error, setError] = useState<string | null>(null);
  const [callStatus, setCallStatus] = useState<'idle' | 'ringing' | 'calling' | 'connected' | 'ended'>('idle');
  const [incomingCall, setIncomingCall] = useState<{caller: string} | null>(null);

  useEffect(() => {
    try {
      if (config) {
        voip.initialize(config);

        voip.on('connected', () => {
          setStatus('connected');
          setError(null);
        });

        voip.on('disconnected', () => {
          setStatus('disconnected');
        });

        voip.on('registrationFailed', (data: any) => {
          setError(`Registratie mislukt: ${data.cause}`);
          setStatus('disconnected');
        });

        voip.on('incomingCall', (data: any) => {
          setCallStatus('ringing');
          setIncomingCall({
            caller: data.caller
          });
        });

        voip.on('callAccepted', () => {
          setCallStatus('connected');
          setIncomingCall(null);
        });

        voip.on('callEnded', () => {
          setCallStatus('ended');
          setIncomingCall(null);
        });

        voip.on('callFailed', (data: any) => {
          setError(`Gesprek mislukt: ${data.cause}`);
          setCallStatus('idle');
          setIncomingCall(null);
        });
      }
    } catch (err) {
      setError('Fout bij initialiseren VoIP service');
    }

    return () => {
      if (voip) {
        voip.disconnect();
      }
    };
  }, [config]);

  const makeCall = (extension: string) => {
    try {
      setError(null);
      setCallStatus('calling');
      voip.makeCall(extension);
    } catch (err) {
      setError('Fout bij het opzetten van gesprek');
      setCallStatus('idle');
    }
  };

  const answerCall = () => {
    try {
      voip.answerCall();
    } catch (err) {
      setError('Fout bij het beantwoorden van gesprek');
      setCallStatus('idle');
    }
  };

  const rejectCall = () => {
    try {
      voip.rejectCall();
      setCallStatus('idle');
      setIncomingCall(null);
    } catch (err) {
      setError('Fout bij het weigeren van gesprek');
    }
  };

  const endCall = () => {
    try {
      voip.endCall();
      setCallStatus('ended');
    } catch (err) {
      setError('Fout bij het beëindigen van gesprek');
    }
  };

  return {
    status,
    error,
    callStatus,
    incomingCall,
    makeCall,
    answerCall,
    rejectCall, 
    endCall,
    mute: () => voip.mute(),
    unmute: () => voip.unmute(),
    sendDTMF: (tone: string) => voip.sendDTMF(tone),
    isConnected: () => voip.isConnected(),
    isRegistered: () => voip.isRegistered()
  };
}