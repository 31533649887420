import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../services/firebase';

interface Translations {
  [key: string]: {
    [key: string]: string;
  };
}

const translations: Translations = {
  nl: {
    dashboard: 'Dashboard',
    users: 'Gebruikers',
    groups: 'Groepen',
    settings: 'Instellingen',
    profile: 'Profiel',
    logout: 'Uitloggen',
    save: 'Opslaan',
    cancel: 'Annuleren',
    error: 'Fout',
    success: 'Succes',
    loading: 'Laden...',
    // Voeg hier meer vertalingen toe
  },
  en: {
    dashboard: 'Dashboard',
    users: 'Users',
    groups: 'Groups',
    settings: 'Settings',
    profile: 'Profile',
    logout: 'Logout',
    save: 'Save',
    cancel: 'Cancel',
    error: 'Error',
    success: 'Success',
    loading: 'Loading...',
    // Add more translations here
  },
  de: {
    dashboard: 'Dashboard',
    users: 'Benutzer',
    groups: 'Gruppen',
    settings: 'Einstellungen',
    profile: 'Profil',
    logout: 'Abmelden',
    save: 'Speichern',
    cancel: 'Abbrechen',
    error: 'Fehler',
    success: 'Erfolg',
    loading: 'Laden...',
    // Fügen Sie hier weitere Übersetzungen hinzu
  },
  fr: {
    dashboard: 'Tableau de bord',
    users: 'Utilisateurs',
    groups: 'Groupes',
    settings: 'Paramètres',
    profile: 'Profil',
    logout: 'Déconnexion',
    save: 'Enregistrer',
    cancel: 'Annuler',
    error: 'Erreur',
    success: 'Succès',
    loading: 'Chargement...',
    // Ajoutez plus de traductions ici
  }
};

interface LanguageContextType {
  language: string;
  setLanguage: (lang: string) => Promise<void>;
  t: (key: string) => string;
}

const LanguageContext = createContext<LanguageContextType | null>(null);

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}

export function LanguageProvider({ children }: { children: React.ReactNode }) {
  const [language, setLanguageState] = useState('nl');

  useEffect(() => {
    loadLanguagePreference();
  }, []);

  async function loadLanguagePreference() {
    try {
      const configDoc = await getDoc(doc(db, 'system_config', 'settings'));
      if (configDoc.exists()) {
        const { settings } = configDoc.data();
        if (settings?.language) {
          setLanguageState(settings.language);
        }
      }
    } catch (error) {
      console.error('Error loading language preference:', error);
    }
  }

  async function setLanguage(lang: string) {
    try {
      await updateDoc(doc(db, 'system_config', 'settings'), {
        'settings.language': lang,
        updatedAt: new Date().toISOString()
      });
      setLanguageState(lang);
      // Optioneel: Herlaad de pagina om alle vertalingen direct toe te passen
      window.location.reload();
    } catch (error) {
      console.error('Error updating language:', error);
      throw error;
    }
  }

  const t = (key: string): string => {
    return translations[language]?.[key] || translations['nl'][key] || key;
  };

  const value = {
    language,
    setLanguage,
    t
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
}