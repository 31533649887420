import React, { useState, useEffect } from 'react';
import { Headphones, Mic, Volume2 } from 'lucide-react';

interface AudioDevice {
  deviceId: string;
  label: string;
  kind: MediaDeviceKind;
}

interface Props {
  onDeviceChange: (deviceId: string, kind: MediaDeviceKind) => void;
}

function AudioDeviceSelector({ onDeviceChange }: Props) {
  const [audioDevices, setAudioDevices] = useState<AudioDevice[]>([]);
  const [selectedOutput, setSelectedOutput] = useState<string>('');
  const [selectedInput, setSelectedInput] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    loadAudioDevices();
    // Luister naar apparaat wijzigingen (bijv. Bluetooth verbinding)
    navigator.mediaDevices.addEventListener('devicechange', loadAudioDevices);
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', loadAudioDevices);
    };
  }, []);

  async function loadAudioDevices() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevs = devices.filter(device => 
        device.kind === 'audiooutput' || device.kind === 'audioinput'
      ).map(device => ({
        deviceId: device.deviceId,
        label: device.label || `${device.kind === 'audioinput' ? 'Microfoon' : 'Speaker'} (${device.deviceId.slice(0, 5)})`,
        kind: device.kind as MediaDeviceKind
      }));

      setAudioDevices(audioDevs);

      // Automatisch Bluetooth apparaat selecteren indien beschikbaar
      const bluetoothOutput = audioDevs.find(dev => 
        dev.kind === 'audiooutput' && dev.label.toLowerCase().includes('bluetooth')
      );
      const bluetoothInput = audioDevs.find(dev => 
        dev.kind === 'audioinput' && dev.label.toLowerCase().includes('bluetooth')
      );

      if (bluetoothOutput) {
        handleDeviceChange(bluetoothOutput.deviceId, 'audiooutput');
      }
      if (bluetoothInput) {
        handleDeviceChange(bluetoothInput.deviceId, 'audioinput');
      }
    } catch (err) {
      setError('Fout bij het laden van audio apparaten');
    }
  }

  function handleDeviceChange(deviceId: string, kind: MediaDeviceKind) {
    if (kind === 'audiooutput') {
      setSelectedOutput(deviceId);
    } else {
      setSelectedInput(deviceId);
    }
    onDeviceChange(deviceId, kind);
  }

  return (
    <div className="space-y-4">
      {error && (
        <div className="text-red-600 text-sm">{error}</div>
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2 flex items-center">
          <Headphones className="h-4 w-4 mr-2" />
          Audio uitvoer
        </label>
        <select
          value={selectedOutput}
          onChange={(e) => handleDeviceChange(e.target.value, 'audiooutput')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
        >
          {audioDevices
            .filter(device => device.kind === 'audiooutput')
            .map(device => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label}
              </option>
            ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2 flex items-center">
          <Mic className="h-4 w-4 mr-2" />
          Microfoon
        </label>
        <select
          value={selectedInput}
          onChange={(e) => handleDeviceChange(e.target.value, 'audioinput')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
        >
          {audioDevices
            .filter(device => device.kind === 'audioinput')
            .map(device => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
}

export default AudioDeviceSelector;