import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useRoleAccess, ROLES } from '../../hooks/useRoleAccess';
import { LogOut, Users, MessageSquare, Phone, Calendar, User, Settings, Cloud } from 'lucide-react';

function Navigation() {
  const { currentUser, userData, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { hasAccess: isAdmin } = useRoleAccess([ROLES.ADMIN, ROLES.SUPERADMIN]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Uitloggen mislukt:', error);
    }
  };

  const isActive = (path: string) => {
    return location.pathname.startsWith(path) ? 'text-primary' : 'text-gray-700 hover:text-primary';
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <Link to="/" className="flex items-center text-xl font-bold text-primary">
              VoIP Group Manager
            </Link>
          </div>

          {currentUser && (
            <div className="hidden sm:flex sm:items-center sm:space-x-8">
              <Link 
                to="/dashboard" 
                className={`flex items-center ${isActive('/dashboard')}`}
              >
                Dashboard
              </Link>
              
              <Link 
                to="/voip" 
                className={`flex items-center ${isActive('/voip')}`}
              >
                <Phone className="h-5 w-5 mr-1" />
                VoIP
              </Link>
              
              <Link 
                to="/sms" 
                className={`flex items-center ${isActive('/sms')}`}
              >
                <MessageSquare className="h-5 w-5 mr-1" />
                SMS
              </Link>
              
              <Link 
                to="/calendar" 
                className={`flex items-center ${isActive('/calendar')}`}
              >
                <Calendar className="h-5 w-5 mr-1" />
                Agenda
              </Link>

              {isAdmin && (
                <>
                  <Link 
                    to="/admin/voip" 
                    className={`flex items-center ${isActive('/admin/voip')}`}
                  >
                    <Phone className="h-5 w-5 mr-1" />
                    VoIP Instellingen
                  </Link>
                  <Link 
                    to="/admin/settings" 
                    className={`flex items-center ${isActive('/admin/settings')}`}
                  >
                    <Settings className="h-5 w-5 mr-1" />
                    Instellingen
                  </Link>
                  <Link 
                    to="/admin/microsoft365" 
                    className={`flex items-center ${isActive('/admin/microsoft365')}`}
                  >
                    <Cloud className="h-5 w-5 mr-1" />
                    Microsoft 365
                  </Link>
                </>
              )}

              <div className="flex items-center space-x-4 ml-4 pl-4 border-l border-gray-200">
                <div className="flex items-center">
                  <User className="h-5 w-5 text-gray-400 mr-2" />
                  <div className="text-sm">
                    <p className="font-medium text-gray-700">{userData?.email}</p>
                    <p className="text-gray-500 text-xs">{userData?.role}</p>
                  </div>
                </div>
                
                <Link 
                  to="/profiel" 
                  className={`${isActive('/profiel')}`}
                >
                  Profiel
                </Link>
                
                <button
                  onClick={handleLogout}
                  className="flex items-center text-gray-700 hover:text-red-600"
                >
                  <LogOut className="h-5 w-5 mr-1" />
                  Uitloggen
                </button>
              </div>
            </div>
          )}

          {!currentUser && (
            <div className="flex items-center space-x-4">
              <Link 
                to="/login" 
                className={`${isActive('/login')}`}
              >
                Inloggen
              </Link>
              <Link 
                to="/registreren" 
                className={`${isActive('/registreren')}`}
              >
                Registreren
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navigation;