import { auth, db } from '../services/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, collection, getDocs, query, where, addDoc } from 'firebase/firestore';
import { ROLES } from '../hooks/useRoleAccess';

interface NewUser {
  email: string;
  password: string;
  companyName: string;
  role: typeof ROLES[keyof typeof ROLES];
  groupId: string;
}

export async function addUser(userData: NewUser) {
  try {
    // Check if user already exists
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('email', '==', userData.email));
    const existingUser = await getDocs(q);
    
    if (!existingUser.empty) {
      throw new Error(`Gebruiker ${userData.email} bestaat al`);
    }

    // Create Firebase Auth user
    const userCredential = await createUserWithEmailAndPassword(
      auth, 
      userData.email, 
      userData.password
    );

    // Create Firestore user document
    await setDoc(doc(db, 'users', userCredential.user.uid), {
      email: userData.email,
      companyName: userData.companyName,
      role: userData.role,
      status: 'active',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    });

    // Add user to group
    if (userData.groupId) {
      await addDoc(collection(db, 'group_members'), {
        groupId: userData.groupId,
        userId: userCredential.user.uid,
        role: userData.role === ROLES.ADMIN ? 'admin' : 'member',
        joinedAt: new Date().toISOString()
      });
    }

    // Update role count
    const roleCountRef = doc(db, 'role_counts', userData.role);
    const roleCountDoc = await getDocs(collection(db, 'role_counts'));
    const currentCount = roleCountDoc.docs.find(doc => doc.id === userData.role)?.data()?.count || 0;

    await setDoc(roleCountRef, {
      count: currentCount + 1,
      updatedAt: new Date().toISOString()
    });

    console.log(`Gebruiker succesvol aangemaakt: ${userData.email}`);
    return userCredential.user;

  } catch (error: any) {
    console.error('Fout bij het aanmaken van gebruiker:', error.message);
    throw error;
  }
}