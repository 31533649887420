import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Phone, MessageSquare, Calendar, Settings } from 'lucide-react';

function Dashboard() {
  const { currentUser, userData } = useAuth();

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-2xl font-bold mb-2">Welkom bij VoIP Group Manager</h1>
        <p className="text-gray-600">
          {userData?.companyName ? `${userData.companyName} - ` : ''}
          {currentUser?.email}
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <Link
          to="/voip"
          className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
        >
          <div className="flex items-center space-x-4">
            <Phone className="h-8 w-8 text-primary" />
            <div>
              <h2 className="text-lg font-semibold">VoIP</h2>
              <p className="text-gray-600">Beheer uw telefoongesprekken</p>
            </div>
          </div>
        </Link>

        <Link
          to="/sms"
          className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
        >
          <div className="flex items-center space-x-4">
            <MessageSquare className="h-8 w-8 text-primary" />
            <div>
              <h2 className="text-lg font-semibold">SMS</h2>
              <p className="text-gray-600">Verstuur en beheer berichten</p>
            </div>
          </div>
        </Link>

        <Link
          to="/calendar"
          className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
        >
          <div className="flex items-center space-x-4">
            <Calendar className="h-8 w-8 text-primary" />
            <div>
              <h2 className="text-lg font-semibold">Agenda</h2>
              <p className="text-gray-600">Plan en beheer afspraken</p>
            </div>
          </div>
        </Link>

        {userData?.role === 'admin' || userData?.role === 'superadmin' ? (
          <Link
            to="/admin"
            className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
          >
            <div className="flex items-center space-x-4">
              <Settings className="h-8 w-8 text-primary" />
              <div>
                <h2 className="text-lg font-semibold">Beheer</h2>
                <p className="text-gray-600">Beheer instellingen en gebruikers</p>
              </div>
            </div>
          </Link>
        ) : null}
      </div>
    </div>
  );
}

export default Dashboard;