import React, { useState, useEffect } from 'react';
import { Settings } from 'lucide-react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { useRoleAccess, ROLES } from '../../../hooks/useRoleAccess';

function GeneralSettings() {
  const { hasAccess } = useRoleAccess([ROLES.ADMIN, ROLES.SUPERADMIN]);
  const [settings, setSettings] = useState({
    language: 'nl',
    timezone: 'Europe/Amsterdam',
    dateFormat: 'DD-MM-YYYY',
    maintenance: {
      enabled: false,
      message: 'Het systeem is momenteel in onderhoud. Probeer het later opnieuw.',
      allowedIps: []
    }
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    loadSettings();
  }, []);

  async function loadSettings() {
    try {
      const configDoc = await getDoc(doc(db, 'system_config', 'settings'));
      if (configDoc.exists()) {
        setSettings(configDoc.data().settings);
      }
    } catch (err) {
      setError('Fout bij het laden van instellingen');
    } finally {
      setLoading(false);
    }
  }

  async function handleSave() {
    try {
      setSaving(true);
      setError('');
      setSuccess('');

      await updateDoc(doc(db, 'system_config', 'settings'), {
        settings,
        updatedAt: new Date().toISOString()
      });

      setSuccess('Instellingen succesvol opgeslagen');
    } catch (err) {
      setError('Fout bij het opslaan van instellingen');
    } finally {
      setSaving(false);
    }
  }

  if (!hasAccess) {
    return (
      <div className="text-center py-8 text-red-600">
        U heeft geen toegang tot deze pagina.
      </div>
    );
  }

  if (loading) {
    return (
      <div className="text-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary mx-auto"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center mb-6">
        <Settings className="h-6 w-6 text-primary mr-2" />
        <h1 className="text-2xl font-bold">Algemene Instellingen</h1>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      {success && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
          {success}
        </div>
      )}

      <div className="bg-white rounded-lg shadow-md p-6 space-y-6">
        <div>
          <h2 className="text-lg font-semibold mb-4">Systeem Instellingen</h2>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Taal</label>
              <select
                value={settings.language}
                onChange={(e) => setSettings({ ...settings, language: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
              >
                <option value="nl">Nederlands</option>
                <option value="en">English</option>
                <option value="de">Deutsch</option>
                <option value="fr">Français</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Tijdzone</label>
              <select
                value={settings.timezone}
                onChange={(e) => setSettings({ ...settings, timezone: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
              >
                <option value="Europe/Amsterdam">Amsterdam (UTC+1)</option>
                <option value="Europe/London">London (UTC+0)</option>
                <option value="Europe/Paris">Paris (UTC+1)</option>
                <option value="Europe/Berlin">Berlin (UTC+1)</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Datum Formaat</label>
              <select
                value={settings.dateFormat}
                onChange={(e) => setSettings({ ...settings, dateFormat: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
              >
                <option value="DD-MM-YYYY">DD-MM-YYYY</option>
                <option value="MM-DD-YYYY">MM-DD-YYYY</option>
                <option value="YYYY-MM-DD">YYYY-MM-DD</option>
              </select>
            </div>
          </div>
        </div>

        <div>
          <h2 className="text-lg font-semibold mb-4">Onderhoudsmodus</h2>
          
          <div className="space-y-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={settings.maintenance.enabled}
                onChange={(e) => setSettings({
                  ...settings,
                  maintenance: {
                    ...settings.maintenance,
                    enabled: e.target.checked
                  }
                })}
                className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm text-gray-900">
                Onderhoudsmodus inschakelen
              </label>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Onderhoudsbericht
              </label>
              <textarea
                value={settings.maintenance.message}
                onChange={(e) => setSettings({
                  ...settings,
                  maintenance: {
                    ...settings.maintenance,
                    message: e.target.value
                  }
                })}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            onClick={handleSave}
            disabled={saving}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            {saving ? 'Bezig met opslaan...' : 'Instellingen opslaan'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default GeneralSettings;