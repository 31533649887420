import React, { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import { SMSConfig } from '../types/sms';

interface Props {
  config: SMSConfig;
  onUpdate: (config: Partial<SMSConfig>) => void;
  disabled?: boolean;
}

function SMSProviderConfig({ config, onUpdate, disabled = false }: Props) {
  const [showSecrets, setShowSecrets] = useState(false);

  const handleProviderToggle = (provider: 'smsgateway' | 'cheapconnect') => {
    const updatedConfig = {
      ...config,
      providers: {
        ...config.providers,
        smsgateway: {
          ...config.providers.smsgateway,
          enabled: provider === 'smsgateway'
        },
        cheapconnect: {
          ...config.providers.cheapconnect,
          enabled: provider === 'cheapconnect'
        }
      },
      activeProvider: provider
    };
    onUpdate(updatedConfig);
  };

  const handleProviderConfigChange = (
    provider: 'smsgateway' | 'cheapconnect',
    field: string,
    value: string
  ) => {
    onUpdate({
      providers: {
        ...config.providers,
        [provider]: {
          ...config.providers[provider],
          [field]: value
        }
      }
    });
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium mb-4">SMS Provider Instellingen</h3>

        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div>
              <h4 className="font-medium">SMS Gateway API</h4>
              <p className="text-sm text-gray-500">Primaire SMS provider (SMSTools.be)</p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={config.providers.smsgateway.enabled}
                onChange={() => handleProviderToggle('smsgateway')}
                disabled={disabled}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary/50 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"></div>
            </label>
          </div>

          {config.providers.smsgateway.enabled && (
            <div className="pl-4 border-l-2 border-gray-200 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Client ID
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    type={showSecrets ? 'text' : 'password'}
                    value={config.providers.smsgateway.clientId || ''}
                    onChange={(e) => handleProviderConfigChange('smsgateway', 'clientId', e.target.value)}
                    disabled={disabled}
                    className="block w-full pr-10 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm"
                  />
                  <button
                    type="button"
                    onClick={() => setShowSecrets(!showSecrets)}
                    className="absolute inset-y-0 right-0 px-3 flex items-center"
                  >
                    {showSecrets ? (
                      <EyeOff className="h-4 w-4 text-gray-400" />
                    ) : (
                      <Eye className="h-4 w-4 text-gray-400" />
                    )}
                  </button>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Client Secret
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    type={showSecrets ? 'text' : 'password'}
                    value={config.providers.smsgateway.clientSecret || ''}
                    onChange={(e) => handleProviderConfigChange('smsgateway', 'clientSecret', e.target.value)}
                    disabled={disabled}
                    className="block w-full pr-10 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm"
                  />
                  <button
                    type="button"
                    onClick={() => setShowSecrets(!showSecrets)}
                    className="absolute inset-y-0 right-0 px-3 flex items-center"
                  >
                    {showSecrets ? (
                      <EyeOff className="h-4 w-4 text-gray-400" />
                    ) : (
                      <Eye className="h-4 w-4 text-gray-400" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="flex items-center justify-between mt-6">
            <div>
              <h4 className="font-medium">CheapConnect</h4>
              <p className="text-sm text-gray-500">Backup SMS provider</p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={config.providers.cheapconnect.enabled}
                onChange={() => handleProviderToggle('cheapconnect')}
                disabled={disabled}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary/50 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"></div>
            </label>
          </div>

          {config.providers.cheapconnect.enabled && (
            <div className="pl-4 border-l-2 border-gray-200">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  API Key
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    type={showSecrets ? 'text' : 'password'}
                    value={config.providers.cheapconnect.apiKey || ''}
                    onChange={(e) => handleProviderConfigChange('cheapconnect', 'apiKey', e.target.value)}
                    disabled={disabled}
                    className="block w-full pr-10 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm"
                  />
                  <button
                    type="button"
                    onClick={() => setShowSecrets(!showSecrets)}
                    className="absolute inset-y-0 right-0 px-3 flex items-center"
                  >
                    {showSecrets ? (
                      <EyeOff className="h-4 w-4 text-gray-400" />
                    ) : (
                      <Eye className="h-4 w-4 text-gray-400" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SMSProviderConfig;