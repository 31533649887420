import React from 'react';

interface Props {
  server: {
    primary: string;
    alternative: string;
    port: number;
    useAlternative: boolean;
  };
  onChange: (server: Props['server']) => void;
  disabled?: boolean;
}

function ServerSettings({ server, onChange, disabled }: Props) {
  return (
    <div>
      <h2 className="text-lg font-semibold mb-4">Server Instellingen</h2>
      
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Primaire Server
          </label>
          <input
            type="text"
            value={server.primary}
            onChange={(e) => onChange({ ...server, primary: e.target.value })}
            disabled={disabled}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Alternatieve Server
          </label>
          <input
            type="text"
            value={server.alternative}
            onChange={(e) => onChange({ ...server, alternative: e.target.value })}
            disabled={disabled}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Server Poort
          </label>
          <select
            value={server.port}
            onChange={(e) => onChange({ ...server, port: parseInt(e.target.value) })}
            disabled={disabled}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
          >
            <option value="5060">5060 (Standaard)</option>
            <option value="5070">5070</option>
            <option value="5072">5072</option>
            <option value="5074">5074</option>
            <option value="5076">5076</option>
            <option value="5078">5078</option>
            <option value="5080">5080 (Aanbevolen)</option>
          </select>
          <p className="mt-1 text-sm text-gray-500">
            Poort 5080 wordt aanbevolen om SIP ALG problemen te voorkomen
          </p>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            checked={server.useAlternative}
            onChange={(e) => onChange({ ...server, useAlternative: e.target.checked })}
            disabled={disabled}
            className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
          />
          <label className="ml-2 block text-sm text-gray-900">
            Gebruik alternatieve server
          </label>
        </div>
      </div>
    </div>
  );
}

export default ServerSettings;