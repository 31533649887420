import React, { useState } from 'react';
import { Phone, X, Plus, Mic, MicOff } from 'lucide-react';

interface Props {
  onCall: (number: string) => void;
  onHangup: () => void;
  onMute: () => void;
  disabled?: boolean;
  isMuted?: boolean;
}

function VoIPDialer({ onCall, onHangup, onMute, disabled, isMuted }: Props) {
  const [number, setNumber] = useState('');
  const [isDialpadOpen, setIsDialpadOpen] = useState(false);

  const handleKeyPress = (key: string) => {
    setNumber(prev => prev + key);
  };

  const handleDelete = () => {
    setNumber(prev => prev.slice(0, -1));
  };

  const handleCall = () => {
    if (number) {
      onCall(number);
    }
  };

  const dialpadKeys = [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9'],
    ['*', '0', '#']
  ];

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      {/* Nummer weergave */}
      <div className="flex items-center justify-between mb-4">
        <input
          type="tel"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          placeholder="Voer nummer in..."
          className="w-full text-2xl font-semibold text-gray-700 focus:outline-none"
        />
        {number && (
          <button onClick={handleDelete} className="text-gray-400 hover:text-gray-600">
            <X className="h-5 w-5" />
          </button>
        )}
      </div>

      {/* Nummerpad */}
      {isDialpadOpen && (
        <div className="grid grid-cols-3 gap-4 mb-6">
          {dialpadKeys.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              {row.map((key) => (
                <button
                  key={key}
                  onClick={() => handleKeyPress(key)}
                  className="w-full h-12 rounded-full bg-gray-100 hover:bg-gray-200 text-xl font-medium text-gray-700"
                >
                  {key}
                </button>
              ))}
            </React.Fragment>
          ))}
        </div>
      )}

      {/* Besturing */}
      <div className="flex justify-center space-x-6">
        <button
          onClick={() => setIsDialpadOpen(!isDialpadOpen)}
          className="p-4 rounded-full bg-gray-100 hover:bg-gray-200 text-gray-700"
        >
          <Plus className="h-6 w-6" />
        </button>

        <button
          onClick={handleCall}
          disabled={disabled || !number}
          className={`p-4 rounded-full ${
            disabled || !number
              ? 'bg-gray-200 text-gray-400'
              : 'bg-green-500 hover:bg-green-600 text-white'
          }`}
        >
          <Phone className="h-6 w-6" />
        </button>

        <button
          onClick={onMute}
          className={`p-4 rounded-full ${
            isMuted 
              ? 'bg-red-500 text-white' 
              : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
          }`}
        >
          {isMuted ? <MicOff className="h-6 w-6" /> : <Mic className="h-6 w-6" />}
        </button>
      </div>

      {/* Actieve gesprek indicator */}
      {disabled && (
        <div className="mt-4 text-center">
          <button
            onClick={onHangup}
            className="px-6 py-2 bg-red-500 text-white rounded-full hover:bg-red-600"
          >
            Ophangen
          </button>
        </div>
      )}
    </div>
  );
}

export default VoIPDialer;