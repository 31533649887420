import React, { useState } from 'react';
import { Trash2, Edit, Check, X } from 'lucide-react';
import { deleteCalendar, updateCalendar } from '../services/calendarService';
import type { Calendar, CalendarType } from '../types';

interface Props {
  calendars: Calendar[];
  onRefresh: () => void;
  selectedUserId?: string | null;
}

const CALENDAR_TYPES: Record<CalendarType, string> = {
  personal: 'Persoonlijk',
  work: 'Werk',
  shared: 'Gedeeld',
  resource: 'Resource'
};

function CalendarList({ calendars = [], onRefresh, selectedUserId }: Props) {
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editingType, setEditingType] = useState<CalendarType>('personal');
  const [error, setError] = useState('');

  const handleDelete = async (calendarId: string) => {
    if (!window.confirm('Weet u zeker dat u deze agenda wilt verwijderen?')) {
      return;
    }

    try {
      await deleteCalendar(calendarId);
      onRefresh();
    } catch (err) {
      setError('Fout bij het verwijderen van agenda');
    }
  };

  const handleEdit = (calendar: Calendar) => {
    setEditingId(calendar.id);
    setEditingType(calendar.type);
  };

  const handleSave = async (calendarId: string) => {
    try {
      await updateCalendar(calendarId, { type: editingType });
      setEditingId(null);
      onRefresh();
    } catch (err) {
      setError('Fout bij het bijwerken van agenda type');
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <h2 className="text-lg font-semibold mb-4">
        {selectedUserId ? 'Gebruiker Agenda\'s' : 'Mijn Agenda\'s'}
      </h2>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <div className="space-y-3">
        {calendars.map(calendar => (
          <div
            key={calendar.id}
            className="flex items-center justify-between p-3 rounded-lg border border-gray-200"
          >
            <div className="flex items-center">
              <div
                className="w-4 h-4 rounded-full mr-3"
                style={{ backgroundColor: calendar.color }}
              />
              <div>
                <h3 className="font-medium">{calendar.name}</h3>
                {editingId === calendar.id ? (
                  <div className="flex items-center space-x-2 mt-1">
                    <select
                      value={editingType}
                      onChange={(e) => setEditingType(e.target.value as CalendarType)}
                      className="text-sm border border-gray-300 rounded px-2 py-1"
                    >
                      {Object.entries(CALENDAR_TYPES).map(([value, label]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                    <button
                      onClick={() => handleSave(calendar.id)}
                      className="text-green-600 hover:text-green-800"
                    >
                      <Check className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => setEditingId(null)}
                      className="text-gray-600 hover:text-gray-800"
                    >
                      <X className="h-4 w-4" />
                    </button>
                  </div>
                ) : (
                  <p className="text-sm text-gray-500">
                    {CALENDAR_TYPES[calendar.type as CalendarType] || calendar.type}
                  </p>
                )}
              </div>
            </div>

            <div className="flex space-x-2">
              <button
                onClick={() => handleEdit(calendar)}
                className="text-blue-600 hover:text-blue-800"
                title="Type bewerken"
              >
                <Edit className="h-5 w-5" />
              </button>
              <button
                onClick={() => handleDelete(calendar.id)}
                className="text-red-600 hover:text-red-800"
                title="Agenda verwijderen"
              >
                <Trash2 className="h-5 w-5" />
              </button>
            </div>
          </div>
        ))}

        {calendars.length === 0 && (
          <p className="text-gray-500 text-center py-4">
            Geen agenda's gevonden
          </p>
        )}
      </div>
    </div>
  );
}

export default CalendarList;